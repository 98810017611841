.forWho {
  position: relative;
  padding: 20px 0 40px;
}

.forWho__title {
  font-family: "Avenir Next", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: center;
  color: #303030;
}

.delivery__numbers--forWho {
  font-family: "Inter" !important;
}

.forWho__text {
  margin: 10px 0;
  font-family: "Avenir Next", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 46.8px;
  text-align: center;
  color: #303030;
}

.forWho__description {
  font-family: "Avenir Next", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  color: #303030;
}

.forWho__mob {
  display: block !important;
}
