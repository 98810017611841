.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 5;

  &__container {
    padding: 0 100px;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__heading {
    background-color: #22252B;
  }

  &__heading--wrapper {
    width: 100%;
    height: 116px;
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__heading--left {
    flex: 1;
    display: flex;
    gap: 16px;
    height: 32px;
    align-items: center;

    svg {
      width: 26px;
      height: 26px;
    }
  }

  &__input {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-size: 24px;
    font-family: "Gilroy", sans-serif;
    font-weight: 400;
    line-height: 1.5;


  }

  &__heading--right {
    width: 20px;
    height: 20px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #fff;
      }
    }
  }

  &__body {
    background-color: #fff;
    padding: 24px 0;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__title {
    width: 100%;
    font-family: "Gilroy", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.5;
    display: flex;
    gap: 16px;
    align-items: center;

    svg {
      width: 32px;

      path {
        width: 32px;
        fill: #8C8C8C;
      }
    }

  }

  &__items--wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    .lastSeen__item {
      max-width: 100%;
    }

  }

  &__message {
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    color: #22252B;
  }


}

@media screen and (max-width: 1450px) {
  .search__items--wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1153px) {
  .search__items--wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media screen and (max-width: 768px) {
  .search {
    &__container {
      max-width: 768px;
      padding: 0 16px;
      gap: 24px;
    }

    &__items--wrapper {
      gap: 8px;
    }

    &__heading--wrapper {
      height: 60px;
    }

    &__heading--right {
      width: 15px;
      height: 15px;
    }

    &__title {
      font-size: 24px;
      svg {
        width: 24px;
        path {
          width: 24px;
        }
      }
    }
    &__message {
      font-size: 16px;
    }
  }

}

@media screen and (max-width: 660px) {
  .search__items--wrapper {
    grid-template-columns: 1fr;
  }
}
