.sales{
    padding: 75px 0;
    background-image: url('../assets//images/sale__bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.sales__title{
    font-family: "Georgia", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 1.7549999952316284px;
    text-align: center;
    color: #303030;
    margin-bottom: 15px;
}

.sales__text{
    margin-bottom: 35px;
    font-family: "CenturyGothic";
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.38999998569488525px;
    text-align: center;
    color: #303030;
}

.sales__accent{
    font-weight: 700;
}

.sales__description{
    margin-bottom: 20px;
    font-family: "Georgia", sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1.1699999570846558px;
    text-align: center;
    color: #fcb25f;
}

.sales__banner{
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 0 auto 25px;
    padding-left: 35px;
    background-color: #FFFFFF;
    border-radius: 137px;
    font-family: "CenturyGothic";
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 5px;
}

.sales__modal{
    width: 100%;
    padding: 40px 10px;
    background-color: #FFFFFF;
}

.sales__modal--wrapper{
    width: 100%;
    border-radius: 5px;
    border: 15px solid rgba(255, 255, 255, 0.5);
}

.sales__input{
    width: 100%;
    height: 58px;
    margin-bottom: 25px;
    padding-left: 30px;
    border: 1px solid #4F4F4F80;
    background-color: transparent;
    border-radius: 50px;
    font-family: "CenturyGothic", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

    &::placeholder {
        font-family: "CenturyGothic", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        color: #4F4F4F;
    }

    &:focus {
        box-shadow: inset 0px -5px 15px 0px rgba(255, 255, 255, 0.1);
    }
}

.sales__image{
    display: none;
}

.sales__subtitle{
    margin-bottom: 25px;
    font-family: "CenturyGothic", sans-serif;
    font-size: 21px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.38999998569488525px;
    text-align: center;
    color: #FCB25F;
}

.sales__button--wrapper{
    width: 100%;
    height: 71px;
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #FDE7CE80;
    border-radius: 80px;
    cursor: pointer;
}

.sales__button{
    cursor: pointer;
    color: #000000;
    background: linear-gradient(355.74deg, #fcb25f 34.5%, #fde7ce 111.35%), linear-gradient(347.59deg, #fcb25f 21.12%, #fde7ce 97.18%);
    border-radius: 80px;
    box-shadow: 0px 0px 15px #fcb25f;
    border: transparent;
    width: 100%;
    height: 53px;
    font-family: "CenturyGothic", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0.8190000057220459px;
    text-align: center;
}

.sales__info{
    font-family: "CenturyGothic", sans-serif;
    font-size: 12.5px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.2930000126361847px;
    text-align: center;
}

@media screen and (min-width: 1000px) {
    .input__error--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        max-width: 330px;
        width: 100%;
        height: 97px;
    }
    
    .sales__image{
        display: block;
        width: 660px;
        height: 593px;
    }

    .sales__wrapper{
        display: flex;
    }

    .sales__input{
        max-width: 330px;
        height: 66px;
        margin: 0;
    }

    .sales__description{
        font-size: 30px;
        text-align: left;
        margin-bottom: 30px;
    }

    .sales__button--wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 376px;
        height: 97px;
        margin: 0;
    }

    .sales__button{
        max-width: 344px;
        height: 66px;
        font-size: 16px;
    }

    .sales__image--wrapper{
        width: 54%;
    }

    .sales__text--wrapper{
        width: 46%;
    }

    .sales__banner{
        margin: 0;
        color:#303030;
        border-radius: 137px;
    }

    .sales{
        padding: 85px 0 252px;
        position: relative;
    }

    .sales__text{
        font-size: 24px;
        line-height: 155.4%;
        letter-spacing: 0.025em;
        text-align: left;
    }

    .sales__title{
        line-height: 38px;
        font-size: 50px;
        margin-bottom: 40px;
    }

    .sales__subtitle{
        font-size: 25px;
        margin-bottom: 35px;
    }

    .sales__form--wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        margin-bottom: 20px;
    }

    .sales__modal--wrapper{
        position: absolute;
        bottom: -5%;
        max-width: 1240px;
    }
}

@media screen and (max-width: 370px) {
    .sales__subtitle{
        font-size: 18px;
    }

    .sales__button{
        font-size: 13px;
    }
}

@media screen and (max-width: 355px) {
    .sales__subtitle {
        font-size: 16px;
    }

    .sales__button {
       font-size: 12.5px;
     }
}