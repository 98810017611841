.novaPoshta__wrappper {
  max-width: 848px;
  width: 100%;
  margin-bottom: 72px;
}

.novaPoshta__thumb {
  padding: 24px;
  border: 2px solid #fcb25f;
  border-radius: 10px;
}

.novaPoshta__method--wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
}

.novaPoshta__method--choose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  max-width: 32px;
  height: 32px;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  cursor: pointer;
}

.novaPoshta__option {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #22252b;
}

.novaPoshta__method--choosen {
  width: 16px;
  height: 16px;
  background: #fcb25f;
  border-radius: 50%;
}

.novaPoshta__delivery--wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.novaPoshta__delivery--thumb {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

.novaPoshta__label {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #22252b;
}

.novaPoshta__select {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  max-width: 388px;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  background: #f5f5f5;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  cursor: pointer;
}

.novaPoshta__select--error {
  border: 1px solid #ff4d4f;

  & .novaPoshta__select--placeholder {
    color: #ff4d4f;
  }
}

.novaPoshta__select--placeholder {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #8c8c8c;
}

.novaPoshta__modal--wrapper {
  position: absolute;
  z-index: 2;
  top: 68px;
  left: 0;
  max-width: 388px;
  width: 100%;
  max-height: 264px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000029;
  border-radius: 4px;
}

.novaPoshta__modal--input {
  max-width: 372px;
  width: 100%;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  border-radius: 4px;
  padding: 8px 0 8px 10px;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #22252b;
  outline: none;

  &::placeholder {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #8c8c8c;
  }
}

.cart__list::-webkit-scrollbar {
  width: 12px;
  background: #f0f0f0;
  border-radius: 12px;
}

.cart__list::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 6px;
  border: 2px solid #f0f0f0;
}

.novaPoshta__modal--list {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 200px;
}

.novaPoshta__modal--item {
  padding: 8px 10px;
  cursor: pointer;

  &:hover {
    background: #fff9f3;
  }
}

@media screen and (max-width: 1000px) {
  .novaPoshta__wrappper {
    margin-bottom: 80px;
  }

  .novaPoshta__delivery--wrapper {
    flex-direction: column;
  }
}
