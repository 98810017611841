.hero {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 115px;
}

.hero__carousel {
  height: auto;
  max-width: 100%;
}

.custom-indicators {
  display: flex;
  gap: 4px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 6px;
}

.indicator.active {
  border: 1px solid #fdb360 !important;
  background: #22252b !important;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff !important;
  border: none !important;
  cursor: pointer;
}

.hero__title {
  text-align: center;
  font-family: "Georgia", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 39.39px;
  letter-spacing: 2.34px;
  text-transform: uppercase;
}

.hero__title--accent {
  margin-top: 5px;
  color: #fff;
  text-align: center;
  font-family: "Georgia", sans-serif;
  font-size: 39px;
  font-style: normal;
  font-weight: 400;
  line-height: 39.39px;
  letter-spacing: 2.34px;
  text-transform: uppercase;
}

.hero__description {
  max-width: 300px;
  width: 100%;
  margin: 15px auto 0;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 15.6px;
  font-style: normal;
  font-weight: 400;
  line-height: 24.18px;
  letter-spacing: 0.39px;
}

.hero__button__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 74px;
  border: 1px solid rgba(253, 231, 206, 0.5);
  border-radius: 80px;
  margin: 35px auto 45px;
  cursor: pointer;
}

.hero__button {
  width: 211px;
  height: 53px;
  background: linear-gradient(355.74deg, #fcb25f 34.5%, #fde7ce 111.35%),
    linear-gradient(347.59deg, #fcb25f 21.12%, #fde7ce 97.18%);
  border-radius: 80px;
  border: transparent;
  box-shadow: 0px 0px 15px #fcb25f;
  color: #000000;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 13.7px;
  font-style: normal;
  font-weight: 700;
  line-height: 53.63px;
  letter-spacing: 0.819px;
}

.hero__numbers {
  height: 37px;
  overflow: hidden;
  font-family: "CenturyGothic", sans-serif;
  font-size: 66.432px;
  font-style: normal;
  font-weight: 700;
  line-height: 54.6px;
  letter-spacing: 1.706px;
  opacity: 0.25;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.hero__info {
  display: flex;
  justify-content: center;
}

.hero__text {
  font-family: "Georgia", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.91px;
  background: linear-gradient(#fcb25f, #fde7ce);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero__text--description {
  margin-top: 3px;
  color: #fcb25f;
  font-family: "CenturyGothic", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.7px;
}

.hero__list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 20px;
  padding-bottom: 40px;
}

.hero__info::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 368px;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.1);
}

.hero__facebook {
  display: none;
}

.hero__pc {
  display: none;
}

.hero__mob {
  height: auto;
}

.custom-indicators--pc {
  display: none;
}

@media screen and (min-width: 1000px) {
  .hero__pc {
    display: block;
  }

  .hero__mob {
    display: none;
  }

  .custom-indicators {
    display: none;
  }

  .custom-indicators--pc {
    display: flex;
    gap: 4px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    cursor: pointer;
  }
  // .hero{
  // padding-top: 240px;
  // padding-bottom: 50px;
  // background-image: url('../assets/images/main-screen__bg.webp');
  // }

  // .hero::after{
  //     content: "";
  //     position: absolute;
  //     right: 140px;
  //     top: 0px;
  //     height: calc(100% + 15rem);
  //     width: 1px;
  //     background: -webkit-gradient(linear, left top, left bottom, color-stop(25.94%, rgba(255, 255, 255, 0.2)), color-stop(70.44%, rgba(255, 255, 255, 0.2)), to(rgba(255, 255, 255, 0)));
  //     background: -o-linear-gradient(top, rgba(255, 255, 255, 0.2) 25.94%, rgba(255, 255, 255, 0.2) 70.44%, rgba(255, 255, 255, 0) 100%);
  //     background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 25.94%, rgba(255, 255, 255, 0.2) 70.44%, rgba(255, 255, 255, 0) 100%);
  // }

  // .hero::before {
  //     content: "";
  //     position: absolute;
  //     right: 300px;
  //     top: 0px;
  //     height: calc(100% + 15rem);
  //     width: 1px;
  //     background: -webkit-gradient(linear, left top, left bottom, color-stop(25.94%, rgba(255, 255, 255, 0.2)), color-stop(70.44%, rgba(255, 255, 255, 0.2)), to(rgba(255, 255, 255, 0)));
  //     background: -o-linear-gradient(top, rgba(255, 255, 255, 0.2) 25.94%, rgba(255, 255, 255, 0.2) 70.44%, rgba(255, 255, 255, 0) 100%);
  //     background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 25.94%, rgba(255, 255, 255, 0.2) 70.44%, rgba(255, 255, 255, 0) 100%);
  // }

  .hero__facebook {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 50px;
  }

  .hero__title--wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .hero__title {
    font-size: 70px;
    line-height: 92.39px;
    text-align: start;
  }

  .hero__title--accent {
    font-size: 70px;
    text-align: start;
    line-height: 45.39px;
  }

  .hero__description {
    text-align: start;
    margin: 8px 0 0;
  }

  .hero__info {
    justify-content: flex-start;
  }

  .hero__text--description {
    margin-top: 5px;
  }

  .hero__list {
    display: flex;
    flex-direction: row;
    gap: 125px;
    padding: 25px 0 0;
  }

  .hero__item {
    display: flex;
    flex-direction: column-reverse;
  }

  .hero__button__wrapper {
    width: 290px;
    height: 97px;
    margin: 40px 0 50px;
  }

  .hero__button {
    font-size: 16px;
    width: 257px;
    height: 66px;
  }

  .hero__numbers {
    line-height: 78.6px;
    height: 55px;
    margin-top: 20px;
    font-size: 110px;
  }

  .hero__info::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 148px;
    pointer-events: none;
    background: rgba(255, 255, 255, 0.1);
  }
}
@media screen and (max-width: 768px) {
  .hero {
    padding-top: 60px;
  }
}
