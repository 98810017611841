.oneClick__modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 740px;
  width: 100%;
  padding-bottom: 32px;
  background: #ffffff;
}

.cart__oneClick--wrapper {
  justify-content: flex-end;
}

.oneClick__wrapper {
  padding: 32px 24px 0;
}

.oneClick__title--wrapper {
  border-bottom: 1px solid #f5f5f5;
}

.oneClick__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px 0 32px;
}

.oneClick__input--wrapper {
  display: flex;
  gap: 24px;
}

.oneClick__input--thumb {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.oneClick__input--text {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #22252b;
}

.oneClick__input {
  width: 100%;
  padding: 8px 0 8px 16px;
  border: 1px solid #d0d0d0;
  background: #f5f5f5;
  border-radius: 4px;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #22252b;
  outline: none;

  ::placeholder {
    color: #8c8c8c;
  }
}

.oneClick__description {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8c8c8c;
}

.oneClick__button {
  width: 100%;
  padding: 12px 0;
  background: #22252b;
  border-radius: 4px;
  border: transparent;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}

.oneClick__input--error {
  border: 1px solid #ff4d4f;

  &::placeholder {
    color: #ff4d4f;
  }
}

.oneClick__success {
  padding: 0 24px;
}

.oneClick__image {
  max-width: 164px;
  width: 100%;
  margin: 48px auto 24px;
}

.oneClick__success--text {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #22252b;
  margin-bottom: 4px;
}

.oneClick__success--description {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8c8c8c;
  text-align: center;
  margin-bottom: 56px;
}

.onClick__success--button {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  padding: 12px 24px;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  border-radius: 4px;
  border: transparent;
  background: #22252b;
}

@media screen and (max-width: 1000px) {
  .oneClick__title--wrapper {
    border: 0;
  }

  .onClick__success--button {
    max-width: 295px;
    width: 100%;
  }

  .oneClick__success--text {
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .oneClick__success--description {
    font-family: "Gilroy", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 96px;
  }

  .oneClick__wrapper {
    padding: 8px 24px 0;
  }

  .oneClick__image {
    max-width: 124px;
  }

  .oneClick__modal {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 343px;
    width: 100%;
    background: #ffffff;
    padding-bottom: 24px;
  }

  .oneClick__input--wrapper {
    flex-direction: column;
  }

  .oneClick__form {
    margin: 24px 0;
  }
}
