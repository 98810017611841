.isSizeSuit {
  padding: 20px 0;
  background: #22252b;
}

.isSizeSuit__title {
  font-family: "Avenir Next";
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: center;
}

.isSizeSuit__image {
  max-width: 237px;
  width: 100%;
  margin: 15px auto;
}
.isSizeSuit__image2 {
  max-width: 344px;
  width: 100%;
  margin: 0 auto;
}
