.checkout {
  padding-top: 164px;
  padding-bottom: 80px;
}

.checkout__title {
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: #22252b;
  margin-bottom: 16px;
}

.checkout__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.checkout__model--info {
  max-width: 373px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 8px;
}

.checkout__item {
  display: flex;
  justify-content: space-between;
  max-width: 848px;
  width: 100%;
  padding: 16px;
  gap: 30px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}

.checkout__subtitle {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #22252b;
  margin-bottom: 16px;
}

.checkout__name--wrapper {
  display: flex;
  gap: 24px;
  max-width: 848px;
  width: 100%;
}

.checkout__name {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #22252b;
}

.checkout__name--thumb {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
}

.checkout__input--name {
  max-width: 412px;
  width: 100%;
  height: 40px;
  border: 1px solid #d0d0d0;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 8px 0 8px 16px;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #22252b;
  outline: none;

  &::placeholder {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #8c8c8c;
  }
}

.checkout__receiver--wrapper {
  margin: 72px 0;
}

.checkout__payment--thumb {
  max-width: 848px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
}

.checkout__payment--wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.checkout__online--thumb {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.checkout__promocode--text {
  font-family: "Gilroy", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.checkout__wrapper {
  display: flex;
  gap: 72px;
}

.checkout__promocode--wrapper {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
  padding: 24px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}

.checkout__main {
  max-width: 848px;
  width: 100%;
}

.checkout__thumb {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
  gap: 16px;
  position: fixed;
  right: calc((100vw / 2) - (1440px / 2) + 100px);
}

.checkout__promocode--applied {
  display: flex;
  align-items: center;
  gap: 16px;
}

.checkout__promocode--top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.checkout__promocode--open {
  display: flex;
  gap: 8px;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fcb25f;
  border: transparent;
  background: transparent;
  cursor: pointer;
}

.checkout__promocode--opened {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}

.checkout__promocode--button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  background: #22252b;
  border-radius: 4px;
  border: transparent;
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}

.checkout__apply--button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  background: #22252b;
  border-radius: 4px;
  border: transparent;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
}

.checkout__promocode--input {
  padding: 8px 0 8px 10px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #22252b;
  outline: none;

  &::placeholder {
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #8c8c8c;
  }
}

.checkout__data {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 10px;
  background: #f5f5f5;
}

.checkout__data--title {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #22252b;
}

.checkout__delivery {
  display: flex;
  align-items: center;
}

.checkout__delivery--title {
  width: 136px;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8c8c8c;
}

.checkout__delivery--value {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #22252b;
  text-align: right;
}

.checkout__data--line {
  width: 100%;
  height: 1px;
  background: #dcdcdc;
}

.checkout__total {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #22252b;
}

.checkout__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #22252b3d;
  z-index: 2;
}

.checkout__modal {
  max-width: 620px;
  width: 100%;
  padding: 24px 0;
  background-color: #ffffff;
  border-radius: 10px;
}

.checkout__modal--title {
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: #22252b;
}

.checkout__modal--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 24px;
  border-bottom: 1px solid #f5f5f5;
}

.checkout__modal--thumb {
  display: flex;
  flex-direction: column;
}

.checkout__modal--image {
  max-width: 164px;
  width: 100%;
  margin: 46px auto 24px;
}

.checkout__modal--text {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #22252b;
  margin: 0 auto 4px;
}

.checkout__modal--description {
  max-width: 444px;
  width: 100%;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #8c8c8c;
  margin: 0 auto 46px;
}

.checkout__modal--button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 164px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  background: #22252b;
  border-radius: 4px;
  border: transparent;
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  text-transform: uppercase;
}

.checkout__model--price {
  display: none;
}

.checkout__bin--mobile {
  display: none;
}

.checkout__online--wrapper {
  max-width: 190px;
  width: 100%;
  padding: 2px 8px;
  background: #fcb25f;
  border-radius: 4px;
}

.checkout__online--text {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #22252b;
}

.checkout__detalization--wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.checkout__detalization--text {
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #8c8c8c;
}

.checkout__detalization--mobile {
  display: none;
}

.checkout__input--error {
  border: 1px solid #ff4d4f;

  &::placeholder {
    color: #ff4d4f;
  }
}

@media screen and (max-width: 1000px) {
  .checkout {
    padding-top: 124px;
    padding-bottom: 64px;
  }

  .checkout__detalization--mobile {
    display: block;
  }

  .checkout__payment--wrapper.checkout__online {
    flex-direction: column;
  }

  .checkout__online--wrapper {
    max-width: -webkit-fill-available;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .checkout__title {
    margin-bottom: 32px;
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  .checkout__wrapper {
    flex-direction: column;
    padding: 0 24px;
  }

  .checkout__thumb {
    position: static;
  }

  .checkout__item {
    position: relative;
    flex-direction: column;
    gap: 24px;
    padding: 8px;
  }

  .checkout__item .checkout__bin--mobile {
    display: block;
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .cart__item--thumb {
    align-items: flex-start;
  }

  .checkout__stars {
    width: 60px;
    height: 12px;
  }

  .cart__model--image {
    width: 84px;
    height: 84px;
  }

  .checkout__model--info {
    margin-left: 16px;
  }

  .cart__item--wrapper {
    display: none;
  }

  .cart__quantity--thumb {
    width: 64px;
    height: 32px;
  }

  .checkout__item .checkout__minus {
    width: 20px;
    height: 20px;
  }

  .checkout__item .checkout__plus {
    width: 20px;
    height: 20px;
  }

  .cart__quantity--wrapper {
    gap: 8px;
  }

  .checkout__item .cart__thumb {
    justify-content: space-between;
  }

  .checkout__item .cart__model--price {
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .checkout__receiver--wrapper {
    margin: 56px 0 80px;
  }

  .checkout__name--wrapper {
    flex-direction: column;
  }

  .checkout__model--price {
    display: block;
  }

  .checkout__wrapper {
    gap: 56px;
  }

  .checkout__modal--title {
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  .checkout__modal--button {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .checkout__promocode--wrapper {
    max-width: 100%;
  }

  .checkout__thumb {
    max-width: 100%;
  }
}
