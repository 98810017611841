.product {
  padding: 53px 0 30px;
  background-color: #22252b;
}

.product__title {
  max-width: 215px;
  width: 100%;
  margin: 0 auto 10px;
  text-transform: uppercase;
  font-family: "Avenir Next", sans-serif;
  font-size: 29.3px;
  font-weight: 600;
  line-height: 33.64px;
  letter-spacing: 1.7549999952316284px;
  text-align: center;
}

.product__subtitle {
  margin-bottom: 16px;
  color: #ffffff;

  font-family: "Avenir Next", sans-serif;
  font-size: 23.4px;
  font-weight: 600;
  line-height: 31.36px;
  letter-spacing: 1.1699999570846558px;
  text-align: center;
}

.product__description {
  margin-bottom: 16px;
  color: #ffffff;
  font-family: "Avenir Next", sans-serif;
  font-size: 13.7px;
  font-weight: 600;
  line-height: 17.81px;
  text-align: center;
}

.product__button--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 302px;
  height: 71px;
  padding: 8px;
  margin: 0 auto 16px;
  border: 1px solid rgba(253, 231, 206, 0.5);
  border-radius: 80px;
  cursor: pointer;
}

.product__button {
  width: 100%;
  height: 58px;
  border: transparent;
  background: linear-gradient(355.74deg, #fcb25f 34.5%, #fde7ce 111.35%),
    linear-gradient(347.59deg, #fcb25f 21.12%, #fde7ce 97.18%);
  border-radius: 80px;
  box-shadow: 0px 0px 15px #fcb25f;
  cursor: pointer;
  color: #000000;

  font-family: "Avenir Next", sans-serif;
  font-size: 13.7px;
  font-weight: 700;
  line-height: 53.63px;
  letter-spacing: 0.8190000057220459px;
  text-align: center;
}

.product__list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 25px;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}

.product__item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.product__text {
  color: #ffffff;
  text-transform: uppercase;
  font-family: "Avenir Next", sans-serif;
  font-size: 15.6px;
  font-weight: 600;
  line-height: 20.9px;
  letter-spacing: 0.7799999713897705px;
  text-align: left;
}

.product__svg--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid #fcb25f;
}

@media screen and (min-width: 1000px) {
  .product {
    border-bottom: 1px solid rgba(128, 128, 128, 0.7);
  }

  .product__title {
    max-width: none;
  }

  .product__thumb {
    display: flex;
    justify-content: space-between;
  }

  //   .product__button--wrapper {
  //     width: 264px;
  //     height: 96px;
  //     margin: 0;
  //   }

  .product__button {
    // width: 232px;
    // height: 66px;
    font-size: 16px;
  }

  .product__description {
    max-width: 370px;
    width: 100%;
  }

  .product__list {
    margin: 0;
  }

  .product__subtitle {
    font-size: 30px;
  }
}
