.benefits {
  background-color: #22252b;
}

.benefits__wrapper {
  padding: 25px 0;
  background-color: rgba(255, 255, 255, 0.1);
}

.benefits__list {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.benefits__title {
  margin: 12px 0;
  font-family: "Avenir Next", sans-serif;
  font-size: 19.5px;
  font-weight: 600;
  line-height: 22.43px;
  text-align: center;
}

.benefits__description {
  color: #ffffff;

  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20.44px;
  text-align: center;
}

.benefits__image {
  margin: 0 auto;
}

@media screen and (min-width: 1000px) {
  .benefits__list {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .benefits__description {
    max-width: 370px;
    width: 100%;
  }

  .benefits__item {
    display: flex;
    height: fit-content;
  }
}
