.about {
  padding: 30px 0;
  background: #22252b;
  border-bottom: 1px solid rgba(128, 128, 128, 0.7);
}

.about__title {
  font-family: "Georgia", sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 1.7549999952316284px;
  text-align: center;
  text-transform: uppercase;
}

.about__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about__pretitle {
  font-family: "Georgia", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.7799999713897705px;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 8px;
}

.about__text {
  font-family: "CenturyGothic", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 15px;
  color: #ffffff;
}

.about__number {
  font-family: "CenturyGothic", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.4630000591278076px;
  text-align: center;
}

.about__thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #fcb25f;
}

.about__numbers--wrapper {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 50px;
}

.about__top--number {
  font-family: "Inter", sans-serif;
  font-size: 70px;
  font-weight: 700;
  line-height: 89.34px;
  letter-spacing: 2.680999994277954px;
  text-align: center;
  //   border: 1.5px solid #fcb25f;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #fcb25f;
}

.about__middle--info {
  font-family: "Avenir Next", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.64px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (min-width: 1000px) {
  .about {
    padding: 80px 0;
  }

  .about__pretitle {
    max-width: 365px;
    width: 100%;
    font-size: 18px;
  }

  .about__wrapper {
    justify-content: space-between;
  }

  .about__list {
    justify-content: center;
    flex-direction: row;
    margin-top: 25px;
  }

  .about__numbers--wrapper {
    justify-content: center;
    flex-direction: row;
  }

  .about__text {
    max-width: 365px;
    width: 100%;
    font-size: 14px;
  }

  .about__title {
    font-size: 50px;
    line-height: 40px;
  }
}
