.subscription {
    background: rgba(252, 178, 95, 0.48);
}
.subscription__container.container {
    max-width: 1440px;
    padding: 0 100px;
}
.subscription__content {
    display: flex;
    padding: 32px 0px;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}
.subscription__title {
    color: #22252B;
    font-family: "Gilroy", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; 
}

.subscription__form {
    display: flex;
}

.subscription__input {
    display: flex;
    width: 396px;
    padding: 10px 16px;
    align-items: center;
    align-self: stretch;

    border-radius: 4px 0px 0px 4px;
    border: 1px solid #D0D0D0;
    background: #FFF;

    color: #8C8C8C;
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
}

.subscription__btn {
    display: flex;
    height: 48px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    
    border: none;
    border-radius: 0px 4px 4px 0px;
    background: #22252B;

    color: #FFF;
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    text-transform: uppercase;
}

@media screen and (max-width: 1000px) {
    .subscription__title{
        font-size: 24px;
        line-height: 32px;
    }

    .subscription__input {
    height: 40px;
    padding: 10px 16px;
    }
    
    .subscription__btn {
    height: 40px;
    padding: 12px;
    }

    .subscription__content {
        flex-direction: column;
        gap: 24px;
    }
    
}


@media screen and (max-width: 768px) {
    .subscription__container.container{
        padding: 32px 12px;
        max-width: 768px;
    }

    .subscription__form {
        width: 100%;
    }
    .subscription__input {
        width: 100%;
        max-width: 396px;
    }
    
}