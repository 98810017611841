.block {
    display: flex;
    max-width: 1440px;
    padding: 124px 100px;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 72px;
}

.block__image--container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    gap: 20px;
}
.block__title--container {
    display: flex;
   flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.block__title {
    color: #22252B;
    font-family: "Gilroy", sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
}

.block__description {
    overflow: hidden;
    color: #8C8C8C;
    text-overflow: ellipsis;
    font-family: "Gilroy", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.block__button--details {
    display: flex;
    width: 207px;
    height: 48px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    border: none;
    border-radius: 4px;
    background: #22252B;

    color: #FFF;
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    text-transform: uppercase;
}

.block__button--details:hover, .block__button--details:focus {
    background: #000000;
}

.block__image {
    max-width: 672px;
    max-height: 520px;
    width: 100%;
    height: auto;
    object-fit: cover; 
    overflow: hidden;
}

.block__models{
    max-width: 100%;
}

.block__reviews--list--item{
    display: flex;
align-items: flex-start;
justify-content: space-between;
align-self: stretch;
}



@media screen and (max-width: 1000px) {
  .new__top.block__models--top {
    display: flex;
    flex-direction: row-reverse;
}
   
.block__reviews--title--mobile {
    display: flex;
    max-width: 220px;
    margin-bottom: 12px;
    color: #22252B;
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.block__reviews--list--item {
    display: none;
}


.block__reviews {
    width: 100%;
    padding-top: 24px;
}

.block__reviews .slick-dots {
    transform: translateX(50%);
    right: 12px;
   
}

}
@media screen and (min-width: 1000px) {
.new__top.block__models--top {
    display: none;
}

.block__reviews--title--mobile {
    display: none;
}
}


@media screen and (min-width: 768px) {
    .blocks .block:nth-child(even) .block__image--container {
    flex-direction: row-reverse;
}
    
}
@media screen and (max-width: 768px) {
    .block {
        padding: 48px 12px;
        gap: 56px;
        max-width: 768px;
    }

    .block__image--container {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 16px;
}

.block__title--container {
    gap: 8px;
}

.block__title {
    font-size: 24px;
    line-height: 32px;
}

.block__description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
}

.block__button--details {
    height: 40px;
    padding: 12px 24px;
    font-size: 14px;
    line-height: 24px;
}

.block__image {
    max-width: 100%;
    width: 100%;
    height: auto;
    object-fit: cover;
    overflow: hidden;
}
}

@media screen and (min-width: 375px) {
    .block__reviews--title--mobile {
    max-width: 100%;
    }
    .block__reviews .slick-dots {
    top: -38px;
    }
}