.new {
  position: relative;
  padding: 0;
  z-index: 1;
}

.container.new__container {
  max-width: 1440px;
  padding: 64px 100px;
}

.new__image {
  max-width: 170px;
  width: 100%;
  height: auto;
}

.new__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.new__title {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.16;
  //letter-spacing: 0px;
  text-align: left;
  color: #22252B;
}

.new__button--all {
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
}

.new__card--all {
  display: flex !important;
  gap: 3px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #F5F5F5;
  border-radius: 4px;
}

.new__button--all--text {
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #22252B;
}


.new__subtitle {
  font-family: "Avenir Next", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5306122303009033px;
  text-align: left;
  text-transform: uppercase;
}

.new__list {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.new__list--items--desktop {
  display: none;
}

.new__list--items--mobile {
  display: flex;
  gap: 20px;
  width: 100%;
}
.new__thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 183px;
  width: 100%;
  height: auto;
  padding: 20px 6px;
  background: linear-gradient(180deg, #fafafa 0%, rgba(250, 250, 250, 0) 100%);
}

.new__now--price {
  margin-top: 5px;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 900;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.new__old--price {
  // max-width: 32px;
  width: 100%;
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 9px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8c8c;
}

// .new__old--price::after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 30px;
//   height: 1px;
//   background: #8c8c8c;
// }

.new__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
}

.new__stars {
  max-width: 70px;
  width: 100%;
  height: auto;
}

.new__points {
  font-family: "Avenir Next", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  color: #ff9520;
}

.custom__indicators {
  display: flex;
  gap: 15px;
  position: absolute;
  right: 15px;
  top: 30px;
}



.indicators__custom.active {
  background: linear-gradient(0deg, #fcb260, #fcb260),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)) !important;
  border: 3px solid #303030 !important;
}

.indicators__custom {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #303030 !important;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .indicators__pc {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .indicators__mob {
    display: none;
  }

  .new__title {
    font-size: 48px;
  }

  .new__list--items--desktop {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .new__list--items--mobile {
    display: none !important;
  }
  .new__list {
    gap: 11px;
  }

  .new__thumb {
    max-width: 302px;
    pad: 32px 10px;
  }

  .new__image {
    max-width: 280px;
  }

  .indicators__custom {
    width: 18px;
    height: 18px;
  }

  .indicators__custom.active {
    border: 4px solid #303030 !important;
  }

  .custom__indicators {
    right: 140px;
  }

  .new__title {
    font-size: 39px;
    line-height: 36px;
  }

  .new__subtitle {
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.874734103679657px;
  }

  .new__now--price {
    font-size: 25px;
    line-height: 35px;
  }

  .new__old--price {
    max-width: 55px;
    font-size: 15px;
    line-height: 17px;
  }

  .new__old--price::after {
    width: 50px;
    height: 1.5px;
  }

  .new__points {
    font-size: 19px;
    line-height: 22px;
  }

  .new__stars {
    max-width: 112px;
  }

  .new__wrapper {
    margin-top: 20px;
  }

  .model__newPrice {
    //font-size: 20px;
    //line-height: 1.1;
  }
}


@media screen and (max-width: 1300px) {

  .model__type,
  .model__new{
    font-size: 10px;
    line-height: 10px;
  }

  .model__name,
  .model__description {
    font-size: 12px;
  }

  .model__stars {
    width: 60px;
  }

  .model__content {
    gap: 8px;
  }

  .model__oldPrice,
  .model__discount{
    font-size: 10px;
    line-height: 1.1;
  }

  .model__newPrice {
    font-size: 24px;
    line-height: 1.1;
  }
}


.new__list--items--mobile {
  .slick-dots {
    bottom: -24px;
    z-index: -1;
  }

  .slick-dots li button:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #F5F5F5;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    background-color: #22252B;
    opacity: 1;
    border: 1px solid #FDB360;
  }

  .slick-dots li,
  .slick-dots li button{
    width: 8px;
    height: 8px;
  }

  .slick-track {
   
    display: flex;
    .slick-slide
    {
      height: inherit !important;
      & > div {
        height: 100%;
      }
    }

  }

  .slick-slide {
  padding: 0 6px; 
}

.slick-list {
  margin: 0 -6px; 
}
}

@media screen and (max-width: 768px) {
    .container.new__container {
      padding: 48px 12px;
      max-width: 768px;
    }

    
    
}

@media screen and (max-width: 425px) {
    
  .model__newPrice {
      font-size: 16px;
      line-height: 1;
    }
}

@media screen and (max-width: 375px) {
    .new__button--all--text {
      font-size: 12px;
    }

    .model__type, .model__new {
      font-size: 8px;
      line-height: 8px;
    }
    .model__stars {
      width: 50px;
    }
}