.smsSent__modal {
  position: absolute;
  max-width: 359px;
  width: 100%;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #ffffff;
}

.smsSent__title {
  margin-bottom: 7px;
  font-family: "Avenir Next", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 37.86px;
  letter-spacing: 1px;
  text-align: center;
  color: #292d34;
  text-transform: uppercase;
}

.smsSent__text {
  margin-bottom: 19px;
  font-family: "Avenir Next", sans-serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #292d34;
}

.smsSent__line {
  border: 1px solid #d1d1d1;
}

.smsSent__info {
  margin: 19px 0;

  font-family: "Avenir Next", sans-serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #292d34;
}

.smsSent__button {
  max-width: 319px;
  width: 100%;
  height: 58px;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 17.67px;
  color: #ffffff;
  background: #292d34;
  cursor: pointer;
}

.smsSent__description {
  max-width: 320px;
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: -15%;
  transform: translate(-50%, -50%);
  font-family: "Avenir Next", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.67px;
  text-align: center;
  color: #cfcfcf;
}
