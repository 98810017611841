.cart__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  z-index: 11;
  background: #22252b3d;
}

.cart__modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
}

.cart__line {
  height: 1px;
  border-radius: 4px;
  background: #f5f5f5;
}

.cart__cross {
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.cart__img--empty {
  width: 164px;
  height: 164px;
}

.cart__line2 {
  max-width: 284px;
  width: 100%;
  margin: 0 auto 20px;
  border-radius: 4px;
  height: 4px;
  background: linear-gradient(90deg, #fcb25f 0.7%, #fde7ce 100%);
}

.cart__title {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #22252b;
}

.cart__title--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.cart__wrapper {
  margin: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cart__text {
  margin: 24px 0 4px;
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #22252b;
}

.cart__description--empty {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8c8c8c;
}

.cart__model--image {
  width: 124px;
  height: 124px;
}

.cart__item--thumb {
  display: flex;
  align-items: center;
}

.cart__model--name {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #22252b;
}

.cart__model--color {
  font-family: "Avenir Next", sans-serif;
  font-size: 12.3px;
  font-weight: 500;
  line-height: 15.99px;
  color: #292d34;
}

.cart__model--price {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #22252b;
}

.cart__quantity--wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
}

.cart__model__quantity {
  font-family: "Avenir Next", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 1px;
  color: #000000;
}

.cart__thumb {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.cart__quantity--thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.cart__model--quantity {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #22252b;
}

.cart__list--wrapper {
  padding: 24px;
}

.cart__item--wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.cart__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 340px;
}

.cart__list::-webkit-scrollbar {
  width: 12px;
  background: #f0f0f0;
  border-radius: 12px;
}

.cart__list::-webkit-scrollbar-thumb {
  background: #8c8c8c;
  border-radius: 6px;
  border: 2px solid #f0f0f0;
}

.cart__item {
  display: flex;
  padding: 16px;
  gap: 30px;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}

.cart__model--info {
  max-width: 460px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 8px;
}

.cart__start--wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cart__item--description {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8c8c8c;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

.cart__recommendations {
  margin-bottom: 10px;
  font-family: "Avenir Next", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  color: #000000;
}

.cart__recommendations--list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart__recommendations--item {
  display: flex;
  max-width: 358px;
  width: 100%;
  padding: 4px 10px;
  border-radius: 8px;
  background: linear-gradient(90deg, #fcb25f 0%, #fde7ce 100%);
}

.cart__recommendations--title {
  font-family: "Avenir Next", sans-serif;
  font-size: 12.3px;
  font-weight: 500;
  line-height: 15.99px;
  color: #292d34;
  margin-bottom: 2px;
}

.cart__recommendations--wrapper {
  width: 100%;
  padding: 10px 0;
}

.cart__recommendations--thumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart__recommendations--button {
  font-family: "Avenir Next", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: center;
  border: transparent;
  background-color: transparent;
  text-transform: uppercase;
  color: #292d34;
  cursor: pointer;
}

.cart__recommendations--price {
  font-family: "Avenir Next", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.9px;
  letter-spacing: 1px;
  color: #292d34;
}

.cart__recommendations--stars {
  margin-bottom: 2px;
}

.cart__model--symbol {
  cursor: pointer;
}

.cart__lastSeen--wrapper {
  position: relative;
  padding: 48px 24px 24px;
}

.cart__lastSeen--text {
  margin-bottom: 16px;
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #22252b;
}

.cart__bottom--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  margin-top: 38px;
  margin-bottom: 7px;
}

.cart__bottom--text {
  font-family: "Avenir Next", sans-serif;
  font-size: 19.06px;
  font-weight: 600;
  line-height: 24.78px;
  color: #292d34;
}

.cart__bottom--button {
  max-width: 358px;
  width: 100%;
  height: 58px;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #292d34;
  border: transparent;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 17.67px;
  color: #ffffff;
  cursor: pointer;
}

.cart__bottom--info {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.67px;
  text-align: center;
}

.cart__bottom--accent {
  font-weight: 700;
}

.cart__top--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
}

.cart__contact--title {
  margin-right: auto;
  font-family: "Avenir Next", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
}

.cart__contact--image {
  margin-right: auto;
}

.cart__contact--line {
  border: 1px solid #d1d1d1;
}

.cart__contact--data {
  font-family: "Avenir Next", sans-serif;
  font-size: 19.06px;
  font-weight: 500;
  line-height: 24.78px;
  color: #292d34;
}

.cart__contact--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cart__contact--total {
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: "Avenir Next", sans-serif;
  font-size: 19.06px;
  font-weight: 600;
  line-height: 24.78px;
}

.cart__total--accent {
  font-weight: 500;
}

.cart__total--old {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.41px;
  color: #26130380;
}

.cart__total--old::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 1px;
  width: 30px;
  background: #26130380;
}

.cart__total--uah {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.41px;
  color: #26130380;
}

.cart__input {
  max-width: 358px;
}

.cart__contact--info {
  font-family: "Avenir Next", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  color: #292d34;
}

.cart__promocode--button {
  padding: 8px 24px;
  background: #22252b;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  border: transparent;
  cursor: pointer;
}

.cart__buttons--wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1;
}

.cart__promo--text {
  color: #000000;
  margin-top: 10px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17.67px;
  text-align: center;
}

.cart__promocode--modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 370px;
  width: 100%;
  min-height: 239px;
  padding: 10px 12px;
  border-radius: 13px;
  background-color: #ffffff;
}

.cart__promocode--backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  z-index: 12;
  background: #000000cc;
}

.cart__promocode--title {
  font-family: "Avenir Next", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  letter-spacing: 1px;
  text-align: center;
  color: #292d34;
  margin-left: auto;
  text-transform: uppercase;
}

.cart__promocode--thumb {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart__promocode--input {
  max-width: 240px;
  width: 100%;
  height: 40px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  padding: 8px 0 8px 10px;
  color: #22252b;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  outline: none;

  &::placeholder {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #8c8c8c;
  }
}

.cart__promocode--error {
  border: 1px solid #fb2121;

  &::placeholder {
    color: #fb2121;
    font-weight: 500;
  }
}

.cart__promocode--cross {
  margin-left: auto;
}

.cart__promocode--applied {
  display: flex;
  align-items: center;
  gap: 12px;
}

.cart__promo--description {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17.67px;
  text-align: center;
  color: #000000;
}

.thanks__totalPrice {
  max-width: 358px;
  width: 100%;
  margin: 10px auto;
  font-family: "Avenir Next", sans-serif;
  font-size: 19.06px;
  font-weight: 600;
  line-height: 24.78px;
  color: #292d34;
}

.cart__lastSeen--list {
  display: flex;
  gap: 24px;
  margin-top: 8px;

  & .slick-track {
    margin: 0;
  }
}

.cart__left--arrow {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px #00000014;
  border-radius: 8px;
  padding: 12px;
  border: transparent;
  transform: rotate(180deg);
  top: 50%;
  left: 0;
  cursor: pointer;
}

.cart__right--arrow {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px #00000014;
  border-radius: 8px;
  padding: 12px;
  border: transparent;
  top: 50%;
  right: 0;
  cursor: pointer;
}

.cart__arrow--icon {
  width: 32px;
  height: 32px;
}

.cart__promocode--wrapper {
  max-width: 952px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin: 20px auto 0;
  background: #fff9f3;
  border-radius: 8px;
  border: 1px solid #fcb25f;
}

.cart__promocode--text {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #22252b;
}

.cart__promocode--success {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #22252b;
}

.cart__checkout--wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.cart__back--button {
  padding: 12px 24px;
  background: #f5f5f5;
  border-radius: 4px;
  border: transparent;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #22252b;
  cursor: pointer;
}

.cart__price--wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cart__checkout--thumb {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px;
  background: #fff9f3;
  border: 1px solid #fcb25f;
  border-radius: 8px;
}

.cart__checkout--button {
  padding: 12px 24px;
  background: #22252b;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  border: transparent;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
}

.cart__checkout--total {
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: #22252b;
}

.cart__checkout--oldTotal {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #8c8c8c;
}

.cart__price--total {
  display: none;
}

.cart__delivery--text {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.cart__delivery--accent {
  font-weight: 600;
}

.cart__delivery--free {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -30px;
  max-width: 1000px;
  width: 100%;
  padding: 12px 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #22252b;
}

@media screen and (max-width: 1000px) {
  .cart__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    background: #22252b3d;
    overflow: hidden;
  }

  .cart__delivery--free {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: unset;
    top: 0;
    max-width: 1000px;
    width: 100%;
    padding: 8px 0;
    border-radius: 0;
    background: #22252b;
  }

  .cart__modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;
    transform: translate(0%, 0%);
  }

  .cart__thumb {
    justify-content: space-between;
  }

  .cart__promocode--wrapper {
    max-width: 386px;
    width: 100%;
    padding: 16px;
  }

  .cart__promocode--wrapper {
    margin: 8px auto 0;
  }

  .cart__back--button {
    display: none;
  }

  .cart__checkout--thumb {
    flex-direction: column;
    width: 100%;
  }

  .cart__checkout--button {
    width: 100%;
  }

  .cart__item {
    flex-direction: column;
  }

  .cart__title--wrapper {
    padding: 20px;
    padding-top: 24px;
    padding-bottom: 12px;
  }

  .cart__checkout--thumb {
    padding: 16px;
    gap: 16px;
  }

  .cart__item {
    gap: 16px;
    padding: 8px;
  }

  .cart__list--wrapper {
    // position: relative;
    margin-top: 12px;
    height: calc(100% - 181px);
    max-height: calc(100% - 181px);
    padding-bottom: 162px;
  }

  .cart__checkout--wrapper {
    margin-top: 16px;
    position: fixed;
    bottom: 16px;
    left: 16px;
    right: 16px;
    z-index: 10;
  }

  .cart__list {
    height: 100%;
    overflow-y: auto !important;
  }

  .cart__price--total {
    display: block;
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #22252b;
  }

  .cart__price--mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cart__promocode--text {
    display: none;
  }

  .cart__promocode--input {
    max-width: max-content;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .cart__promocode--thumb {
    width: 100%;
  }
}

@media screen and (min-height: 725px) and (max-width: 1000px) {
  .cart__checkout--wrapper {
    //position: absolute;
    //bottom: 15vh;
    //left: 50%;
    //transform: translateX(-50%);
    //width: 88%;
  }
}
