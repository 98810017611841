.gift__icon {
  position: fixed;
  z-index: 10;
  bottom: 40px;
  right: 20px;
  width: 54px;
  height: 54px;
  cursor: pointer;
}

.gift__icon--instagram {
  bottom: 110px;
}

.gift__animation {
  animation: gift-shaking 2.5s ease-in-out infinite;
}

.instagram {
  position: fixed;
  bottom: 150px;
  right: 75px;
  width: 283.5px;
  height: 56px;
}

.instagram__square {
  position: fixed;
  width: 283.5px;
  height: 56px;
  z-index: 10;
  bottom: 150px;
  right: 75px;
}

.instagram__text {
  position: relative;
  bottom: -12px;
  left: 20px;
  width: 192px;
  font-family: "Inter", sans-serif;
  font-size: 13.7px;
  font-weight: 500;
  line-height: 15.7px;
  z-index: 10;
}

.instagram__cross {
  position: relative;
  bottom: 25px;
  left: 245px;
  z-index: 10;
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 365px) {
  .instagram__square {
    max-width: 252.5px;
    width: 100%;
    height: 56px;
    right: 60px;
  }

  .gift__icon {
    right: 10px;
  }

  .instagram__text {
    left: 60px;
  }

  .instagram__cross {
    left: 260px;
  }
}

@keyframes gift-shaking {
  0% {
    rotate: 0deg;
  }

  16% {
    rotate: 10deg;
  }

  32% {
    rotate: -10deg;
  }

  48% {
    rotate: 0deg;
  }

  100% {
    rotate: 0deg;
  }
}
