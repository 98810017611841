.smsForm__modal {
  position: absolute;
  max-width: 359px;
  width: 100%;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  background: #ffffff;
}

.smsForm__title {
  font-family: "Avenir Next", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 37.86px;
  letter-spacing: 1px;
  text-align: center;
  color: #292d34;
  text-transform: uppercase;
}

.smsForm__icon {
  position: absolute;
  top: 9px;
  right: 9px;
  cursor: pointer;
}

.smsForm__text {
  margin-top: 7px;
  font-family: "Avenir Next", sans-serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 14.09px;
  text-align: center;
  color: #292d34;
  margin-bottom: 18px;
}

.smsForm__wrapper {
  display: flex;
  justify-content: space-between;
  margin: 19px 0;
}

.smsForm__text--left {
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14.09px;
  text-align: center;
  color: #909090;
}

.smsForm__text--right {
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14.09px;
  color: #bd6f1a;
}

.smsForm__text--timer {
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 14.09px;
  color: #000000;
}

.smsForm__info {
  margin-top: 19px;
  margin-bottom: 10px;
  font-family: "Avenir Next", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: #292d34;
}

.smsForm__input {
  max-width: 319px;
  width: 100%;
  height: 58px;
  padding-left: 22px;
  border-radius: 8px;
  border: 1px solid #292d34;
  color: #292d34;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.67px;
  cursor: pointer;
}

.smsForm__input::placeholder {
  color: #292d34;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.67px;
}

.smsForm__button {
  margin-top: 10px;
  max-width: 319px;
  width: 100%;
  height: 58px;
  border-radius: 8px;
  background: #292d34;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 17.67px;
  color: #fff;
  cursor: pointer;
}

.smsForm__line {
  border: 1px solid #d1d1d1;
}

.smsForm__text--accent {
  font-family: "Avenir Next", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 14.09px;
  text-align: center;
  color: #292d34;
}

.smsForm__description {
  position: absolute;
  left: 50%;
  top: 115%;
  transform: translate(-50%, -50%);
  max-width: 359px;
  width: 100%;
  font-family: "Avenir Next", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.67px;
  // text-align: left;
  color: #cfcfcf;
}
