.lastSeen__item {
  display: flex;
  max-width: 301px;
  width: 100%;
  gap: 16px;
  background: #f5f5f5;
  border-radius: 8px;
}

.lastSeen__item--name {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #22252b;
}

.lastSeen__item--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.lastSeen__item--thumb {
  display: flex;
  flex-direction: column;
}

.lastSeen__item--additional {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #8c8c8c;
}

.lastSeen__item--price {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #22252b;
}

.lastSeen__item--image {
  max-width: 112px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .cart__lastSeen--wrapper .slick-track {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .cart__lastSeen--wrapper .slick-slide {
    width: 100% !important;
  }

  .cart__lastSeen--wrapper .lastSeen__item {
    max-width: -webkit-fill-available;
  }
}
