.models {
  padding: 30px 0 19px;
}

.slide-container {
  position: relative;
}

.indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  gap: 10px;
  margin: 0;
}

.react-slideshow-container
+ ul.indicators
.each-slideshow-indicator.active:before {
  background-color: #fcb260 !important;
  border: 2.5px solid #fff !important;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  width: 10px !important;
  height: 10px !important;
  background-color: #ffffff !important;
}

.react-slideshow-container + ul.indicators .each-slideshow-indicator {
  cursor: pointer;
  opacity: 1 !important;
}

.models__item {
  width: 100%;
  // padding: 15px;
  background: linear-gradient(180deg, #f8f8f8 0%, rgba(248, 248, 248, 0) 100%);
}

.models__wrapper--top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.preHeader {
  position: fixed;
  top: 0;
  z-index: 3;
  height: 30px;
  width: 100%;
  font-size: 15px;
  background: linear-gradient(355.74deg, #fcb25f 34.5%, #fde7ce 111.35%),
  linear-gradient(347.59deg, #fcb25f 21.12%, #fde7ce 97.18%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
}

.subHeader {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  border-radius: 24px 24px 0px 0px;
  background: #292d34;
}

.button__other {
  max-width: 368px;
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "CenturyGothic", sans-serif;
  letter-spacing: 0.06em;
  font-weight: 700;
  border-radius: 80px;
  border: transparent;
  font-size: 16px;
  background: linear-gradient(355.74deg, #fcb25f 34.5%, #fde7ce 111.35%),
  linear-gradient(347.59deg, #fcb25f 21.12%, #fde7ce 97.18%);
  box-shadow: 0px 0px 15px #fcb25f;
  cursor: pointer;
  color: #000000;
}

.models__icon {
  margin-left: 10px;
}

.models__number {
  font-family: "CenturyGothic", sans-serif;
  color: #4f4f4f;
  font-size: 27px;
  font-weight: 700;
  margin-right: 10px;
}

.models__color {
  display: flex;
  align-items: center;
  font-family: "CenturyGothic", sans-serif;
  font-size: 12px;
  line-height: 107.9%;
  color: #4f4f4f;
}

.models__quantity {
  width: 70px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
}

.models__info {
  position: relative;
  // display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
}

.model__description {
  line-height: 16px;
}

.models__type--wrapper {
  position: absolute;
  top: -5%;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 155px;
  width: 100%;
  height: 35px;
  border-radius: 30px;
  background-color: #292d34;
}


.models__type {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(294.24deg, #fcb25f 47%, #fde7ce 88.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.models__discount--wrapper {
  position: absolute;
  top: -5%;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 35px;
  border-radius: 30px;
  background-color: #292d34;
}

.model__hover--toggle--btn {
  rotate: 90deg;
}

.models__back {
  width: 320px;
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 35px;
  font-family: "Avenir Next", sans-serif;
  font-size: 21.51px;
  font-weight: 600;
  line-height: 27.96px;
  text-align: left;
}

.models__pc--wrapper {
  display: flex;
  gap: 75px;
}

.models__discount {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(294.24deg, #fcb25f 47%, #fde7ce 88.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.models__sizes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 395px;
  width: 100%;
  margin-bottom: 10px;
}

.models__size {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 125px;
  height: 32.5px;
  border-radius: 300px;
  background-color: #292d34;
  max-width: 175px;
  width: 100%;
}

.models__image {
  height: 430px;
  margin: 0 auto;
  background-position: center;
  background-size: cover;
}

.models__size--text {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(294.24deg, #fcb25f 47%, #fde7ce 88.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.models__prices {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.models__line {
  background: #ff9520;
  width: 2px;
  height: 18px;
}

.models__info--wrapper {
  display: flex;
  gap: 10px;
}

.models__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 390px;
  width: 100%;
  height: 58px;
  margin: 10px auto;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 17.67px;
  text-align: left;
  border-radius: 8px;
  color: #ffffff;
  background: #303030;
  border: transparent;
  cursor: pointer;
}

.attention__image {
  width: 43px;
  height: 43px;
}

.models__backspace {
  display: flex;
  align-items: center;
  height: 42px;
  gap: 8px;
  padding-left: 13px;
}

.models__backspace--text {
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  text-align: left;
  color: #303030;
}

.attention__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #ff9520;
  max-width: 390px;
  width: 100%;
  padding: 7px;
  margin: 0 auto 12px;
  border-radius: 76px;
}

.attention__text {
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  color: #22252b;
}


.models__size--small {
  max-width: 145px;
}

.models__size--big {
  max-width: 190px;
}


.models__orangeline {
  max-width: 390px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ff9520;
  opacity: 0.3;
}

.models__info--text {
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  text-align: left;
  color: #303030;
}

.models__info--thumb {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 14px 0;
}


.models__colorName--svg {
  width: 46.6px;
  height: 46.6px;
}

.models__colorName--new {
  position: absolute;
  top: 0;
  width: 25.5px;
  height: 13px;
}
.model__hover-buttons .button:hover, .model__hover-buttons .button:focus{
  background: rgba(0, 0, 0, 1);
  color: #FFF;
  border-color: #000000;

}

.model__hover-buttons .button:hover .button__text, .model__hover-buttons .button:focus .button__text{
  color: #FFF;
}


.model__hover-buttons .button:hover svg, .model__hover-buttons .button:focus svg{
  fill: #FFF;
}

.tooltip-container {
  display: flex;
padding: 8px;
justify-content: center;
align-items: center;
width: 260px;

color: #22252B;
font-family: "Gilroy", sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 14px;
}
.model__type-tooltip-trigger {
  padding: 4px;
  text-decoration: none!important;
}
.model__type-tooltip-trigger:hover {
  background: rgba(34, 37, 43, 1);
}
   .model__popup--btn {
    display: block;
  border: none;
  border-radius: 2px;
  background: #6E7073;
  padding: 4px;

overflow: hidden;
color: #FFF;
text-overflow: ellipsis;
font-family: "Gilroy", sans-serif;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 10px;

 }
@media screen and (max-width: 350px) {


  .models__type--wrapper {
    top: -7%;
  }

  .models__discount--wrapper {
    top: -7%;
  }
}


@media screen and (max-width: 1000px) {
  .models__desktop--version {
    display: none;
  }
  div.tooltip-container {
    display: none;
  }
  .model__tooltip {
    display: none;
  }

   .model__popup--btn {
  border: none;
  border-radius: 2px;
  background: #6E7073;
  padding: 4px;

overflow: hidden;
color: #FFF;
text-overflow: ellipsis;
font-family: "Gilroy", sans-serif;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 10px;

cursor: pointer;
 }
}

@media screen and (min-width: 1000px) {
 .model__popup--btn{
  display: none;
 }
   div.tooltip-container {
    display: flex;
  }
  .model__tooltip {
    display: flex;
  }


  .react-slideshow-container
  + ul.indicators
  .each-slideshow-indicator.active:before {
    background-color: #fcb260 !important;
    border: 3px solid #fff !important;
  }

  .react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
    width: 12px !important;
    height: 12px !important;
    background-color: #ffffff !important;
  }

  .models__image {
    height: 468px;
  }

  .models {
    padding: 60px 0 50px;
  }

  .models__item {
    display: flex;
    gap: 75px;
    background: transparent;
  }

  .models__mobile--version {
    display: none;
  }

  .attention__container {
    max-width: 270px;
    border-radius: 53px;
    padding: 4px;
    margin: 0 0 0 auto;
    height: 40px;
  }

  .models__info {
    max-width: 468px;
    margin-bottom: 0;
  }

  .models__right--wrapper {
    max-width: 553px;
    width: 100%;
  }


  .attention__thumb {
    display: flex;
  }

  .attention__text {
    font-family: "Avenir Next", sans-serif;
    font-size: 11.79px;
    font-weight: 600;
    line-height: 16.11px;
  }

  .attention__image {
    width: 30px;
    height: 30px;
  }

  .models__side--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 553px;
    width: 100%;
  }

  .models__prices--wrapper {
    width: 100%;
    display: flex;
    align-items: baseline;
    gap: 17px;
  }


  .models__button {
    max-width: 553px;
    height: 64px;
    margin: 15px 0 0;
    font-size: 16.42px;
    font-weight: 700;
    line-height: 19.34px;
  }

  .models__bottom--info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 65px;
    margin-top: 40px;
  }

  .models__orangeline {
    width: 1px;
    height: 36px;
  }

  .models__info--thumb {
    margin: 0;
  }

  .subHeader {
    display: none;
  }

  .models__colorName--svg {
    width: 54px;
    height: 54px;
  }
}

.models {
  padding-top: 116px;
}

.models *:focus-visible {
  outline: none;
}

.models__container {
  padding: 0px 100px 16px 100px;
  max-width: 1440px;
}

.models__wrapper {
  display: flex;
  gap: 32px;
  width: 100%;
}

.models__slider--wrapper {
  width: 39%;
}


.models__slider {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 100%;
}

.models__slider--main {
  margin-bottom: 8px;
  max-width: 100%;
}

.models__slider--main div img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.models__slider--nav {
  display: flex;
  gap: 8px;

  .slick-track {
    transform: translateX(0) !important;
    width: 100% !important;
    display: flex !important;
  }

  .slick-slide {
    padding: 0 2px !important;
    flex: 0 1 120px !important;
    cursor: pointer;
  }

  .slick-current img {
    border: 4px solid #FDB360;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
    border: 4px solid transparent;
  }
}


.models__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.models__content--wrapper {
  padding: 32px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.models__heading {
  display: flex;
  gap: 16px;
  align-items: center;
  svg path {
    fill: #8c8c8c;
  }
}

.models__name {
  font-family: "Gilroy";
  font-size: 32px;
  font-weight: 500;
  line-height: 1.5;
}

.models__choose {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #22252B;

}

.models__choose--current {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #8C8C8C;
}

.models__colorName {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 50%;
  cursor: pointer;

  img {
    max-width: 100%;
  }

  &.active {
    border: 2px solid #FDB360;
  }
}

.models__colorNames--wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.models__newPrice {
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  color: #22252B;
}

.models__oldPrice {
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  color: #8C8C8C;
  text-decoration: line-through;
}

.models__buttons--container {
  display: flex;
  gap: 16px;
  margin-top: 12px;
}

.models__description {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #8C8C8C;

}

.models__care--heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.models__item--title {
  display: flex;
  gap: 16px;
  align-items: center;
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #8C8C8C;
}

.models__care--cross {
  max-width: 32px;
  max-height: 32px;
  width: 32px;
  height: 32px;
  padding: 4px;
  transform: rotate(45deg);
  transition: all 0.3s ease;
  cursor: pointer;

  &.active {
    transform: rotate(0);
  }

  svg {
    width: 100%;
    height: 100%;
  }

  path {
    fill: #000
  }
}

.models__care--body {
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #22252B;
  margin-top: 0;
  transition: all 0.5s ease;
  max-height: 0;
  overflow: hidden;

  &.active {
    margin-top: 16px;
    max-height: fit-content;
  }
}

.models__care--title {
  font-weight: 700;
  margin: 10px 0;
}

.model__characteristic--item {
  display: flex;
  gap: 8px;
}

.models__characteristic--space {
  flex: 1;
  display: flex;
  align-items: flex-end;
  position: relative;
  top: -3px;
}

.models__characteristic--label {
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #8C8C8C;
}

.models__characteristic--value {
  font-family: "Gilroy";
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #22252B;
}

.models__recommendations {
  padding: 72px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.models__recommendation--title {
  font-family: "Gilroy";
  font-size: 48px;
  font-weight: 600;
  line-height: 1.16;
  color: #22252B;
}

.models__recommendations--list {
  display: flex;
  gap: 20px;
}

.models__rating {
  display: flex;
  gap: 8px;
  align-items: center;
}

.models__stars {
  width: 80px;
}

.models__reviews--number {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #8C8C8C;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.models__sale {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #FCB25F;
  background-color: #FFF9F3;
  border-radius: 4px;
  margin-top: 16px;
  padding: 18px;
}

.models__sale--text {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
}
.models__sale--number {
  font-size: 20px;
  font-weight: 600;
}


@media screen and (max-width: 1145px) {
  .models__buttons--container {
    flex-direction: column;
  }
}


@media screen and (max-width: 768px) {
  .models {
    padding-top: 60px;
  }
  .models__container {
    max-width: 768px;
    padding: 0px 16px 16px 16px;
  }

  .models__wrapper {
    flex-direction: column;
    gap: 0;
  }

  .models__slider--wrapper {
    width: 100%;
  }

  .models__content--wrapper {
    padding: 24px;
  }

  .models__name {
    font-size: 16px;
  }

  .models__recommendations {
    padding: 48px 0;
  }

  .models__recommendation--title {
    font-size: 24px;
  }

  .models__sale {
    padding: 10px 18px;
  }

}




