.delivery {
  padding: 24px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.delivery__title {
  margin: 0 auto 10px;
  text-transform: uppercase;
  text-align: center;
  color: #303030;
  font-family: "Avenir Next", sans-serif;
  font-size: 29.3px;
  font-weight: 600;
  line-height: 33.64px;
  letter-spacing: 1.7549999952316284px;
  text-align: center;
}

.delivery__subtitle {
  margin-bottom: 35px;
  text-align: center;
  color: #303030;
  font-family: "Avenir Next", sans-serif;
  font-size: 15.6px;
  font-weight: 600;
  line-height: 24.18px;
  letter-spacing: 0.38999998569488525px;
}

.delivery__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 35px;
}

.delivery__numbers {
  font-size: 117px;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  letter-spacing: 0.025em;
  height: 85px;
  overflow: hidden;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #fcb25f;
  opacity: 0.5;
}

.delivery__thumb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.delivery__info {
  margin-bottom: 10px;
  color: #22252B;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;

}

.delivery__description {
  color: #8C8C8C;
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;


}

@media screen and (min-width: 1000px) {
  .delivery__wrapper {
    flex-direction: row;
    align-items: flex-start;
  }

  .delivery__title {
    max-width: none;
    margin-bottom: 25px;
    font-size: 50px;
  }
}

@media screen and (max-width: 370px) {
  .delivery__title {
    font-size: 29px;
  }

  .delivery__subtitle {
    font-size: 16px;
  }
}

@media screen and (max-width: 350px) {
  .delivery__subtitle {
    font-size: 15px;
  }
}

@media screen and (max-width: 330px) {
  .delivery__subtitle {
    font-size: 14px;
  }
}
