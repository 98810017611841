.reason {
  padding: 24px 0;
  background-color: #22252b;
}

.reason__title {
  max-width: 317px;
  width: 100%;
  margin: 0 auto 35px;
  font-family: "Avenir Next", sans-serif;
  font-size: 29.3px;
  font-weight: 600;
  line-height: 33.64px;
  letter-spacing: 1.7549999952316284px;
  text-align: center;
}

.reason__list {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.reason__item {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.reason__subtitle {
  font-family: "Avenir Next", sans-serif;
  font-size: 17.6px;
  font-weight: 600;
  line-height: 20.18px;
  text-align: left;
}

.reason__svg--wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1px solid #fcb25f;
}

.reason__svg--thumb {
  position: absolute;
  top: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 46px;
  border-radius: 50%;
  background-color: #22252b;
}

.reason__description {
  color: #ffffff;

  font-family: "Avenir Next", sans-serif;
  font-size: 13.7px;
  font-weight: 600;
  line-height: 19.93px;
  text-align: left;
}

.reason__wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 15px;
}

@media screen and (min-width: 1000px) {
  .reason {
    padding: 24px 0px;
  }

  .reason__title {
    font-size: 50px;
    line-height: 41px;
    margin-bottom: 45px;
  }

  .reason__list {
    flex-direction: row;
    justify-content: space-between;
  }

  .reason__description {
    max-width: 325px;
    width: 100%;
  }

  .reason__item {
    gap: 60px;
  }
}

@media screen and (max-width: 350px) {
  .reason__title {
    font-size: 29px;
  }

  .reason__subtitle {
    font-size: 16.8px;
  }

  .reason__description {
    font-size: 15px;
  }
}
