.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 55;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.5s;
}

.is-hidden {
  pointer-events: none;
  opacity: 0;
}

.modal {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 50px 20px 10px;
  background-image: url("../assets/images/popup__bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.modal__border {
  max-width: 395px;
  width: 100%;
  border-radius: 12px;
  border: 10px solid rgba(255, 255, 255, 0.5);
}

.modal__icon {
  position: absolute;
  right: 20px;
  top: 25px;
  width: 27px;
  height: 27px;
  cursor: pointer;
}

.modal__text {
  margin-bottom: 25px;
  font-size: 27px;
  font-family: "CenturyGothic", sans-serif;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0.005em;
}

.modal__input {
  width: 100%;
  height: 60px;
  margin-bottom: 25px;
  padding-left: 30px;
  border: 1px solid #fcb25f;
  background-color: transparent;
  border-radius: 50px;
  font-family: "CenturyGothic", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;

  &::placeholder {
    font-family: "CenturyGothic", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }

  &:focus {
    box-shadow: inset 0px -5px 15px 0px rgba(255, 255, 255, 0.1);
  }
}

.modal__button--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 269px;
  width: 100%;
  height: 77px;
  margin: 0 auto 15px;
  border: 1px solid rgba(253, 231, 206, 0.5);
  border-radius: 80px;
  cursor: pointer;
}

.modal__button {
  max-width: 250px;
  width: 100%;
  height: 59px;
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  background: linear-gradient(355.74deg, #fcb25f 34.5%, #fde7ce 111.35%),
    linear-gradient(347.59deg, #fcb25f 21.12%, #fde7ce 97.18%);
  border-radius: 80px;
  border: transparent;
  box-shadow: 0px 0px 15px #fcb25f;
  cursor: pointer;
}

.text__description {
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  font-family: "CenturyGothic", sans-serif;
}

.icon__left {
  position: absolute;
  transition: background 0.2s;
  width: 27px;
  height: 2px;
  border-radius: 25%;
  background: #fcb25f;
  transform: rotate(45deg);
}

.icon__right {
  position: absolute;
  transition: background 0.2s;
  width: 27px;
  height: 2px;
  border-radius: 25%;
  background: #fcb25f;
  transform: rotate(-45deg);
}

@media screen and (min-width: 1000px) {
  .modal {
    padding-bottom: 50px;
  }

  .modal__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-bottom: 20px;
  }

  .modal__border {
    max-width: 1200px;
  }

  .modal__input {
    max-width: 232px;
    height: 67px;
    margin: 0;
  }

  .modal__button--wrapper {
    max-width: 310px;
    height: 97px;
    margin: 0;
  }

  .modal__button {
    max-width: 279px;
    height: 66px;
  }

  .modal__button--wrapperCancel {
    max-width: 210px;
  }

  .modal__button--cancel {
    max-width: 178px;
  }
}
