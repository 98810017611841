.moon__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #22252b;
}

.moon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 90px;
    background-color: #fff;
}

.moon__black{
    position: absolute;
    top: -2px;
    left: 2px;
    width: 66px;
    height: 66px;
    border-radius: 90px;
    background-color: #22252b;
    animation-name: moonGrowing;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}

@keyframes moonGrowing {
    0%{
        top: -2px;
        left: 2px;
    }

    100%{
        top: -35px;
        left: 35px;
    }
}