.wheel__backdrop--appearing {
  animation: fadeIn 0.5s;
}

.wheel__wrapper {
  position: absolute;
  top: 105px;
}

.wheel__title {
  font-family: "Avenir Next", sans-serif;
  font-size: 33.94px;
  font-weight: 700;
  line-height: 44.12px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.wheel__text {
  font-family: "Avenir Next", sans-serif;
  font-size: 13.94px;
  font-weight: 600;
  line-height: 18.13px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.wheel__thumb {
  position: relative;
  max-width: 365px;
  width: 100%;
  margin-top: 25px;
}

.wheel__back {
  position: absolute;
}

.wheel {
  max-width: 365px;
  width: 100%;
  position: relative;
}

.wheel__animation1 {
  animation: wheel-animation1 3s ease-in-out forwards;
}

.wheel__animation2 {
  animation: wheel-animation2 3s ease-in-out forwards;
}

.wheel__animation3 {
  animation: wheel-animation3 3s ease-in-out forwards;
}

.wheel__arrow {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
}

.wheel__button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}

.wheel__button--refresh {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
}

.wheel__button--animation {
  animation: shaking-refresh 1s ease-in-out infinite;
}

@keyframes shaking-refresh {
  0% {
    transform: translate(-50%, -50%) rotate(-6deg);
  }

  50% {
    transform: translate(-50%, -50%) rotate(14deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-6deg);
  }
}

@keyframes wheel-animation1 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(2400deg);
  }
}

@keyframes wheel-animation2 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(2100deg);
  }
}

@keyframes wheel-animation3 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(2700deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
