.advantage {
    padding-bottom: 72px;
}
.advantage__container.container {
    max-width: 1440px;
    padding: 0 100px;
}
.advantage__content {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;

    border-radius: 10px;
    background: rgba(252, 178, 95, 0.08);
}

.advantage__item {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0;
}

.advantage__title {
    color: #22252B;
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; 

    text-align: center;
}

.advantage__text {
    color: #8C8C8C;
    text-align: center;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}

@media screen and (max-width: 1210px) {
    .advantage__content {
        gap: 16px;
    }
    
    .advantage__title {
        font-size: 20px;
        line-height: 28px;
    }

    .advantage__item {
        padding: 16px;
    }
    .advantage__text {
        font-size: 12px;
        line-height: 16px;
    }

    .advantage__icon {
        width: 56px;
        height: 56px;
    }
    
}

@media screen and (max-width: 1030px) {
   
    .advantage__content {
        gap: 8px;
    }
    
    .advantage__title {
        font-size: 16px;
        line-height: 24px;
    }

    .advantage__item {
        padding: 8px;
    }
    .advantage__text {
        font-size: 12px;
        line-height: 16px;
    }
}

@media screen and (max-width: 768px) {
    .advantage__container.container{
        max-width: 768px;
        padding: 48px 16px 0;
    }
    
    .advantage__content {
        padding: 24px 16px;
        flex-direction: column;
        gap: 20px;
    }
    .advantage__item {
        padding: 16px;
        width: 100%;
    }
    .advantage__title {
        font-size: 16px;
        line-height: 24px;
    }
    .advantage__text {
        font-size: 12px;
        line-height: 16px;
    }

    .advantage__icon {
        width: 48px;
        height: 48px;
    }
}