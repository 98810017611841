.sms__wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding: 20px 20px 0;
    background-image: url('../assets/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.sms__title{
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    font-weight: 800;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
}

.sms__description{
    font-family: "Montserrat", sans-serif;
    font-size: 19px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.svg__wrapper{
    display: flex;
    flex-direction: column;
    max-width: 387px;
    width: 100%;
    // height: 72px;
    position: relative;
    margin-top: 15px;
}

.sms__button {
    max-width: 387px;
    width: 100%;
    // height: 72px;
    position: relative;
}

.sms__button--wrapper{
    max-width: 387px;
    width: 100%;
}

.sms__icon{
    max-width: 387px;
    width: 100%;
    // height: 72px;
    margin-bottom: -3.8px;
}

.sms__addictional{
    // position: absolute;
    z-index: 3;
    bottom: -50%;
    left: 50%;
    // transform: translateX(-50%);
    // transform: translate(-50%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    width: 100%;
    height: 58px;
    margin: 0 auto;
    background: #FDE8D0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.sms__addictional--two{
    // position: absolute;
    z-index: 3;
    left: 50%;
    bottom: -50%;
    // transform: translateX(-50%);
    // transform: translate(-50%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    height: 77px;
    padding: 10px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #ffffff;
}

.sms__addictional--accent{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0em;
    text-transform: uppercase;
    text-align: center;
}

.sms__addictional--description{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #298CF5;
}

.sms__addictional--text{
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #262932;
}

@media screen and (max-width: 400px) {
    .sms__addictional {
        max-width: 320px;
    }

    .sms__addictional--two{
        max-width: 320px;
    }

    .sms__addictional--text {
        font-size: 15px;
    }

    .sms__addictional--description {
        font-size: 15px;
    }

    .sms__addictional--accent{
        font-size: 15px;
    }
}

@media screen and (max-width: 370px) {
    .sms__title{
        font-size: 32px;
    }
    
    .sms__description{
        font-size: 18px;
    }

    .sms__addictional {
        max-width: 290px;
    }

    .sms__addictional--two{
        max-width: 290px;
    }

    .sms__addictional--text {
        font-size: 14px;
    }

    .sms__addictional--description {
        font-size: 14px;
    }

    .sms__addictional--accent{
        font-size: 14px;
    }
}

@media screen and (max-width: 350px) {
    .sms__title {
        font-size: 28px;
    }

    .sms__description{
        font-size: 17px;
    }

    .sms__addictional {
        max-width: 270px;
    }

    .sms__addictional--two{
        max-width: 270px;
    }

    .sms__addictional--text {
        font-size: 13px;
    }

    .sms__addictional--description {
        font-size: 13px;
    }

    .sms__addictional--accent {
        font-size: 13px;
    }
}

@media screen and (max-width: 335px) {
    .sms__description {
            font-size: 16px;
        }
    .sms__addictional--two{
        max-width: 265px;
    }

    .sms__addictional {
        max-width: 265px;
    }

    .sms__addictional--accent {
        font-size: 12px;
    }

    .sms__addictional--text {
        font-size: 12px;
    }

    .sms__addictional--description {
        font-size: 12px;
    }
}

.border {
    stroke-dasharray: 452;
        /* Circumference of a circle with diameter 72 */
        stroke-dashoffset: 0;
        /* Start with the entire circumference */
        animation: dash 3s linear infinite;
        /* Infinite animation */
}

@keyframes dash {
    from {
            stroke-dashoffset: 452;
            /* Offset to -452 to seamlessly loop */
        }
    to {
        stroke-dashoffset: -452;
        /* Offset to -452 to seamlessly loop */
    }
}