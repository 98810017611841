.review__item {
    width: calc((100% / 2) - 36px);
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: -moz-fit-content;
    height: fit-content;
}

.review__quotes {
    color: #8C8C8C;
font-family: "Gilroy", sans-serif;
font-size: 48px;
font-style: normal;
font-weight: 600;
line-height: 48px;
}
.review__text {
    color: #22252B;
font-family: "Gilroy", sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 28px;
margin-bottom: 8px;
text-wrap: wrap;
}

.review__name {
    color: #8C8C8C;
font-family: "Gilroy", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
}

.review__wrapper {
  display: flex;
padding: 16px;
align-items: flex-start;
gap: 24px;
align-self: stretch;
background: #F5F5F5;
border-radius: 10px;
}

.review__image {
    width: 40%;
}
.review__info {
    display: flex;
}

.review__text--wrapper {
    width: 55%;
}


@media screen and (max-width: 1000px) {
   .review__text--wrapper--mobile {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background: #F5F5F5;
    padding: 16px;

}

.review__content--mobile {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.block__reviews--list--item {
    display: none;
}

.review__wrapper {
    background-color: transparent;
}
.review__text--wrapper {
    display: none;
}

.review__content {
    display: none;
    overflow: hidden;
}

.review__item {

    width: 100%;
}

.review__image {
    width: 30%;
}

.review__quotes {
    font-size: 32px;
    line-height: 32px;
}

.review__text {
    font-size: 16px;
    line-height: 24px;
}

.review__name {
    font-size: 12px;
    line-height: 16px;
}
}
@media screen and (min-width: 1000px) {
   .review__text--wrapper--mobile {
    display: none;

}

.review__content--mobile {
    display: none;
}
}


