.filters__thumb {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin-bottom: 6px;
}

.filters__button {
  margin-bottom: 12px;
  max-width: 362px;
  width: 100%;
  height: 54px;
  border: transparent;
  background: linear-gradient(283.16deg, #fcb25f 47%, #fde7ce 88.91%);
  border-radius: 8px;
  color: #292d34;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 27.67px;
  text-transform: uppercase;
  cursor: pointer;
}

.filters__wrap {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 362px;
  width: 100%;
  margin: 0 auto;
  gap: 6px;
}

.filters__select--type {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 362px;
  width: 100%;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 6.6px 0px #00000026 inset;
  border: 1px solid #d5d5d5;
}

.filters__select--material {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 186px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 6.6px 0px #00000026 inset;
  border: 1px solid #d5d5d5;
}

.filters__select--length {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 178px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 6.6px 0px #00000026 inset;
  border: 1px solid #d5d5d5;
}

.filters__select--color {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 178px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 6.6px 0px #00000026 inset;
  border: 1px solid #d5d5d5;
}

.filters__blocks {
  max-width: 362px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.filters__block {
  display: flex;
  align-items: center;
  height: 25px;
  padding: 6px;
  gap: 4px;
  border: 1px solid rgb(253, 231, 206);
  background: #372e24;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 11.2px;
  border-radius: 4px;
  color: #fcb260;
  cursor: pointer;
}

.filters__select--item {
  padding-bottom: 13px;
  word-break: break-all;
  border-bottom: 2px solid #d5d5d5;
  cursor: pointer;
}

.filters__select--choosen {
  color: #f3972e;
}

.filters__select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  max-width: 178px;
  width: 100%;
  height: 52px;
  border: 1px solid #d5d5d5;
  border-radius: 8px;
  box-shadow: 0px 0px 6.6px 0px #00000026 inset;
  background: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 13.7px;
  font-weight: 500;
  line-height: 15.7px;
  color: #727480;
  user-select: none;
  cursor: pointer;
}

.catalog {
  padding-top: 116px;
}

.catalog__title {
  font-family: "Georgia", sans-serif;
  font-size: 29px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 1.7549999952316284px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 16px;
}

.catalog__description {
  font-family: "Georgia", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 1.7549999952316284px;
  text-align: center;
  text-transform: uppercase;
}

.catalog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 15px;
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 395px;
  width: 100%;
  gap: 8px;
  margin-bottom: 20px;
}

.filter__item--top {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 395px;
  width: 100%;
  height: 52px;
  background: #292d34;
  border: transparent;
  border-radius: 40px;
  cursor: pointer;
}

.filter__text {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  background: linear-gradient(294.24deg, #fcb25f 47%, #fde7ce 88.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.filter__item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  max-width: 395px;
  width: 100%;
}

.filter__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 193px;
  width: 100%;
  height: 52px;
  background: #292d34;
  border: transparent;
  border-radius: 40px;
  cursor: pointer;
}

.filters__button--link {
  display: flex;
  justify-content: center;
  width: 100%;
}

.filter__new {
  position: absolute;
  top: 0;
  right: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 16px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-transform: uppercase;
  text-align: left;
  background: linear-gradient(0deg, #fcb25f, #fcb25f),
    linear-gradient(0deg, #ffffff, #ffffff);
}

.filters__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 14px;
  background: linear-gradient(91.8deg, #1b1e27 0%, #313540 100%);
}

.filters__reset {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.7px;
  color: #b6b7ba;
  text-decoration: underline;
  border: transparent;
  background: transparent;
  cursor: pointer;
}

.model__list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.model__item {
  //max-width: 425px;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  background: #f5f5f5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.model__wrapper--top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.model__name {
  color: #4f4f4f;
  font-weight: 500;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.model__rating {
  display: flex;
  gap: 8px;
  align-items: center;
}

.model__reviews--number {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #8c8c8c;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.model__stars {
  width: 80px;
}

.model__quantity {
  width: 70px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
}

.model__image {
  max-width: 380px;
  width: 100%;
  //min-height: 290px;
}

.model__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  //height: 100%;
}

.model__info {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
}

.model__type--wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  //justify-content: center;
  //align-items: center;
  //max-width: 155px;
  //width: 100%;
  //height: 35px;
  //border-radius: 30px;

  background-color: #6e7073;
  border-radius: 2px;
}

.model__image--wrapper {
  max-width: 380px;
  width: 100%;
  //min-height: 250px;
}

.model__type {
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 1px;
  //text-align: left;
  color: #fff;
}

.model__new--wrapper {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  background-color: #fcb25f;
  padding: 4px;
}

.model__new {
  font-family: "Gilory", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.42;
  text-transform: uppercase;
  color: #22252b;
}

.model__sizes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 395px;
  width: 100%;
  margin-bottom: 10px;
}

.model__size {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 125px;
  height: 32.5px;
  border-radius: 300px;
  background-color: #292d34;
  max-width: 175px;
  width: 100%;
}

.model__size--text {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(294.24deg, #fcb25f 47%, #fde7ce 88.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.model__description {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #8c8c8c;
  display: block;
  margin-bottom: 10px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
}

.model__prices-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.model__price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.model__price-top {
  display: flex;
  gap: 10px;
  align-items: center;
}

.model__prices {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.model__newPrice {
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0em;
  color: #22252b;
}

.model__hover-buttons {
  position: absolute;
  top: calc(100% - 8px);
  left: 0;
  width: 100%;
  padding: 16px;
  display: none;
  flex-direction: column;
  gap: 16px;
  background-color: #f5f5f5;
  border-radius: 0 0 10px 10px;
}

.button {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  height: 48px;
  border-radius: 4px;
  font-family: "Gilroy", sans-serif;
  background-color: #22252b;
  width: 100%;
  cursor: pointer;
  border: transparent;

  &.outline {
    color: #22252b;
    border: 1px solid #22252b;
    background-color: transparent;

    .button__text {
      color: #22252b;
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
  }

  .button__text {
    color: #fff;
    text-transform: uppercase;
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
  }
}

.model__mobilebutton {
  width: 40px;
  height: 40px;
  display: flex;
}

.model__oldPrice {
  //position: relative;
  //opacity: 0.5;
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-decoration: line-through;
  color: #8c8c8c;
}

.model__discount {
  background-color: #ec1d25;
  padding: 4px;
  border-radius: 100px;
  font-family: "Gilroy", sans-serif;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.model__button--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 395px;
  padding: 8px;
  width: 100%;
  height: 77px;
  border: 1px solid rgba(253, 231, 206, 0.5);
  border-radius: 80px;
}

.model__size--small {
  max-width: 145px;
}

.model__size--big {
  max-width: 190px;
}

.model__button {
  width: 100%;
  height: 58px;
  background: linear-gradient(355.74deg, #fcb25f 34.5%, #fde7ce 111.35%),
    linear-gradient(347.59deg, #fcb25f 21.12%, #fde7ce 97.18%);
  border-radius: 80px;
  border: transparent;
  box-shadow: 0px 0px 15px #fcb25f;
  font-family: "CenturyGothic", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0.8190000057220459px;
  text-align: center;
  cursor: pointer;
  color: #000000;
}

.model__color-select {
  position: relative;
  font-family: "Gilroy", sans-serif;
}

.model__selected-color {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  width: 100%;
}

.model__selected-color-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-size: 14px;
    font-weight: 400;
    font-family: "Gilroy", sans-serif;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.model__colors-select-item {
  background-color: #fff;
  padding: 10px 8px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  width: 100%;
  position: absolute;
  top: calc(100% - 4px);
  left: 0;
  display: none;

  &.open {
    display: block;
  }
}

@media screen and (min-width: 1000px) {
  .filters__thumb {
    justify-content: center;
    flex-direction: row;
    margin-bottom: 12px;
  }
  .model__mobilebutton {
    display: none;
  }
  .filters__button--link {
    width: 362px;
  }

  .filters__select {
    max-width: 214px;
    width: 213.5px;
  }

  .filters__wrap {
    width: 433px;
    max-width: 433px;
    margin: 0;
  }

  .filters__blocks {
    max-width: 1016px;
  }

  .filters__button {
    margin-bottom: 0;
  }
  .filters__wrap--pc {
    width: 214px;
  }

  .model__size {
    max-width: 180px;
  }

  .filter__item--top {
    max-width: 240px;
  }

  .catalog__title {
    font-size: 50px;
    margin-bottom: 25px;
  }

  .filter {
    max-width: 1240px;
    margin: 0 auto 20px;
    flex-direction: row;
    gap: 10px;
  }

  .filter__wrapper {
    max-width: 240px;
  }

  .filter__item {
    max-width: 490px;
    gap: 10px;
  }

  .model__item {
    border-radius: 10px;
    padding: 16px;

    &:hover {
      position: relative;

      .model__hover-buttons {
        display: flex;
        z-index: 1;
      }
    }
  }

  .model__list {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    gap: 20px;
  }
}

@media screen and (max-width: 425px) {
  .model__mobilebutton {
    width: 35px;
    height: 35px;
    display: flex;
  }
  .model__reviews--number {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width: 360px) {
  .filter__text {
    font-size: 13px;
  }
}

@media screen and (max-width: 335px) {
  .model__button {
    font-size: 14px;
  }
  .model__reviews--number {
    font-size: 10px;
  }

  .filter__text {
    font-size: 12.5px;
  }
}

.catalog__container {
  padding: 0px 100px 16px 100px;
  max-width: 1440px;
}

.page__heading {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.page__heading--text {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 400;
  font-family: "Gilroy", sans-serif;
}

.catalog__content {
  display: grid;
  gap: 40px;
  grid-template-columns: 280px minmax(100px, 1fr);
}

.catalog__sidebar {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.catalog__categories--title {
  color: #22252b;
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 20px;
  display: block;
}

.catalog__categories--list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 16px;
}

.catalog__categories--item {
  color: #22252b;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  cursor: pointer;

  &.active {
    font-weight: 600;
  }
}

.line__divider {
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
}

.catalog__types {
  display: flex;
  gap: 8px;
}

.catalog__type--button {
  padding: 4px 8px;
  display: flex;
  gap: 4px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  background-color: #f5f5f5;

  span {
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #22252b;
  }

  svg {
    width: 20px;

    path {
      fill: #000;
    }
  }

  &.active {
    background-color: #6e7073;

    span {
      color: #fff;
    }

    svg path {
      fill: #fff;
    }
  }
}

.accordion__heading {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.accordion__title {
  font-family: "Gilroy", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #22252b;
  line-height: 1.33;
}

.accordion__icon {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;

  &.active {
    transform: rotate(180deg);
  }
}

.accordion__body {
  padding-left: 16px;
  max-height: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  margin-top: 0;

  &.active {
    max-height: 100%;
    margin-top: 20px;
  }
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;

  &.is-checked {
    border: 1px solid #fcb25f;
    background-color: #fcb25f;
  }
}

.catalog__filters--list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.catalog__filters--item {
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #22252b;
}

.filter__price {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter__price--inputs {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter__price--container {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 8px;
  align-items: center;
  width: 92px;
  height: 40px;
}

.filter__price--line {
  width: 16px;
  height: 1px;
  background-color: #6e7073;
}

.filter__price--label {
  font-family: "Gilroy", sans-serif;
  color: #6e7073;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5px;
}

.filter__price--input {
  flex: 1;
  width: 100%;
  border: none;
  outline: none;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5px;
}

.filter__price--button {
  background-color: #22252b;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.catalog__main {
  display: flex;
  flex-direction: column;
}

.catalog__main--heading {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.catalog__main--title {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #22252b;
}

.catalog__fastfilters--list {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.catalog__fastfilters--item {
  background-color: #f5f5f5;
  padding: 4px 16px;
  border-radius: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #22252b;
  cursor: pointer;
  white-space: nowrap;

  &.active {
    color: #fff;
    padding: 4px 8px 4px 16px;
    background-color: #6e7073;
  }
}

.catalog__list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 64px;
}

.catalog__sort {
  display: flex;
  align-items: center;
  gap: 16px;
}

.catalog__sort--label {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5px;
  color: #6e7073;
}

.catalog__sort--selected {
  width: 192px;
  height: 40px;
  padding: 0 8px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;

  svg {
    width: 14px;
  }
}

.catalog__sort--value {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5px;
  color: #22252b;
}

.catalog__sort--input {
  position: relative;
}

.catalog__sort--list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 16px 0px #00000014;
  gap: 4px;
  background-color: #ffffff;
  z-index: 10;
  padding: 4px;

  &.active {
    display: flex;
  }
}

.catalog__sort--item {
  padding: 6px 8px;
  color: #22252b;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  transition: background-color 0.3s ease;
  background-color: #fff;
  cursor: pointer;

  &.active {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}

.catalog__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-bottom: 64px;
}

.catalog__pagination--arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 48px;
  border: 1px solid #22252b;
  border-radius: 4px;
  cursor: pointer;

  &.disabled {
    border: 1px solid #b8b9bb;

    svg path {
      fill: #b8b9bb;
    }
  }
}

.catalog__pagination--numbers {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.catalog__pagination--number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  font-family: "Gilroy";
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  background-color: #f5f5f5;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;

  &.active {
    background-color: #fcb25f;
    color: #fff;
  }
}

.catalog__sort--line {
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  margin-bottom: 32px;
}

.catalog__filter--button {
  display: none;
}

.filter__popup {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  padding: 32px 24px;
  overflow: auto;
}

.filter__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.filter__title {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  color: #22252b;
}

.filter__close {
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: #8c8c8c;
    }
  }
}

.filter__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 1184px) {
  .catalog__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 884px) {
  .catalog__content {
    grid-template-columns: minmax(100px, 1fr);
  }
  .catalog__sidebar {
    display: none;
  }
  .catalog__fastfilters--list {
    overflow-x: auto;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .catalog__filter--button {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #fdb360;
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  .catalog__sort--label {
    display: none;
  }

  .catalog__sort--selected {
    display: none;
  }

  .catalog__sort--list {
    right: 0;
    left: auto;
    top: calc(100% - 7px);
    width: 173px;
  }
}

@media screen and (max-width: 768px) {
  .catalog {
    padding-top: 60px;
  }
  .catalog__container {
    max-width: 768px;
    padding: 0px 16px 16px 16px;
  }
}

@media screen and (max-width: 370px) {
  .catalog__pagination--arrow {
    width: 48px;
  }
}

@media screen and (max-width: 330px) {
  .catalog__type--button {
    span {
      font-size: 15px;
    }
  }
}
