.header {
  position: fixed;
  width: 100%;
  z-index: 2;
}
.header__mobile {
  display: none;
}
.header__wrapper--first {
  display: flex;
  height: 48px;
  padding: 0px 100px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0.08) 100%
    ),
    #fff;
}

.nav {
  height: 100%;
}
.nav__list {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-around;

  height: 100%;
}
.nav__link {
  height: 100%;
}
.nav__item {
  display: flex;
  padding: 0px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  color: #22252b;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  height: 100%;
}

.nav__item--sales {
  color: #ec1d25;
}

.header__callback--btn {
  display: flex;
  padding: 0px 32px;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  color: #22252b;
  background-color: transparent;
  border: none;
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;

  cursor: pointer;
}

.header__wrapper--second {
  display: flex;
  height: 68px;
  padding: 0px 100px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #22252b;
}
.header__btn--wrapper {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}
.header__btn--wrapper--second {
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.header__btn {
  display: flex;
  padding: 0px 32px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  color: #fff;
  background-color: transparent;
  border: none;
  border-right: 1px solid rgba(255, 255, 255, 0.16);
  border-left: 1px solid rgba(255, 255, 255, 0.16);
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  cursor: pointer;
}
.header__logo--link {
  cursor: pointer;
}
.header__catalog--btn {
  text-transform: uppercase;
  border-left: none;
}

.header__basket--btn {
  position: relative;
}

.header__cart--exist {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 26px;
  background: #fcb25f;
}

.header__cart--text {
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  color: #22252b;
}

.header__basket--btn--mobile {
  position: relative;
}

@media screen and (min-width: 1440px) {
  .header__wrapper--first {
    padding: 0 calc((100% - 1240px) / 2);
  }
  .header__wrapper--second {
    padding: 0 calc((100% - 1240px) / 2);
  }
}

@media screen and (max-width: 1250px) {
  .nav__item {
    padding: 0px 16px;
    font-size: 12px;
  }

  .header__btn {
    padding: 0px 16px;
    font-size: 12px;
  }
  .header__callback--btn {
    padding: 0px 16px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1060px) {
  .nav__item {
    padding: 0px 8px;
    font-size: 12px;
  }

  .header__btn {
    padding: 0px 8px;
    font-size: 12px;
  }
  .header__callback--btn {
    padding: 0px 8px;
    font-size: 12px;
  }

  .header__cart--exist {
    position: absolute;
    left: 65%;
    top: 35%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
  }

  .header__cart--text {
    font-family: "Gilroy", sans-serif;
    font-size: 8px;
    font-weight: 600;
    line-height: 8px;
  }
}

@media screen and (max-width: 768px) {
  .header__desktop {
    display: none;
  }
  .header__mobile {
    display: block;

    background-color: #22252b;
  }
  .header__wrapper--mobile {
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
  }
  .header__icon--mobile {
    width: 20px;
    height: 20px;
  }
  .header__logo--icon--mobile {
    width: 105.674px;
    height: 32px;
  }
  .header__burger--icon--mobile {
    width: 32px;
    height: 32px;
  }
  .header__catalog--btn--mobile {
    display: flex;
    width: 60px;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border: none;
    border-right: 1px solid #45484d;
    background-color: transparent;
  }

  .header__link--mobile {
    display: flex;
    padding: 14px;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
  }

  .header__btn--mobile {
    display: flex;
    width: 48px;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border: none;
    background-color: transparent;
  }
}
