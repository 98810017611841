.present__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  z-index: 11;
  background: #22252b3d;
}

.present__modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  max-width: 359px;
  width: 100%;
  min-height: 585px;
  padding: 36px 19px 0;
  border-radius: 8px;
}

.present__icon {
  position: absolute;
  top: 9px;
  right: 9px;
}

.present__title {
  font-family: "Avenir Next", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #292d34;
}

.present__description {
  max-width: 290px;
  width: 100%;
  margin: 9px auto -25px;
  font-family: "Avenir Next", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 1px;
  text-align: center;
  color: #292d34;
  text-transform: uppercase;
}

.present__image {
  width: 240px;
  margin: 0 auto;
}

.present__input {
  max-width: 320px;
  width: 100%;
  height: 58px;
  border-radius: 8px;
  margin-bottom: 12px;
  border: 1px solid #292d34;
  padding-left: 22px;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.present__input::placeholder {
  color: #292d34;
}

.present__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  width: 100%;
  height: 58px;
  margin: 0 auto 12px;
  border-radius: 8px;
  background: #292d34;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  border: transparent;
}

.present__text {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #6c6c6c;
}

.telegram__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  z-index: 11;
  background-color: rgba(34, 37, 43, 0.2392156863);
}

.telegram__modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  max-width: 100%;
  width: 392px;
  height: 508px;
  max-height: 100%;
  overflow: auto;
  padding: 32px 24px;
  border-radius: 10px;
}

.telegram__icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #8C8C8C;
    }

  }
}

.telegram__title {
  padding-top: 24px;
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: #000000;
  display: block;
  margin: 0 auto;
  width: 222px;
  max-width: 100%;
}

.telegram__description {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #000000;
  display: block;
  margin: 0 auto 32px;
  width: 222px;
  max-width: 100%;
}

.telegram__accent {
  font-family: "Avenir Next", sans-serif;
  font-size: 67px;
  font-weight: 700;
  line-height: 73px;
  letter-spacing: 0em;
  text-align: center;
  color: #292d34;
  margin-bottom: 13px;
}

.telegram__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  width: 100%;
  height: 58px;
  margin: 0 auto 20px;
  border-radius: 8px;
  background: #292d34;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  border: transparent;
  cursor: pointer;
}

.telegram__declain {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #6c6c6c;
  cursor: pointer;
}

.freeDelivery__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
  z-index: 11;
  background-color: rgba(111, 111, 111, 0.75);
}

.freeDelivery__modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  background-position: top;
  background-repeat: no-repeat;
  max-width: 359px;
  width: 100%;
  height: 410px;
  padding: 14px 12px 0;
  border-radius: 8px;
}

.freeDelivery__icon {
  position: absolute;
  top: 9px;
  right: 9px;
}

.freeDelivery__image {
  width: 170px;
  height: 51.5px;
  margin: 0 auto 10px;
}

.freeDelivery__title {
  font-family: "Avenir Next", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #292d34;
}

.freeDelivery__description {
  max-width: 320px;
  font-family: "Avenir Next", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: center;
  color: #292d34;
  margin-bottom: 24px;
}

.freeDelivery__accent {
  font-family: "Avenir Next", sans-serif;
  font-size: 96px;
  font-weight: 700;
  line-height: 104px;
  letter-spacing: 0em;
  text-align: center;
  color: #292d34;
  margin-bottom: 28px;
}

.freeDelivery__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  width: 100%;
  height: 58px;
  margin: 0 auto 20px;
  border-radius: 8px;
  background: #292d34;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  border: transparent;
  cursor: pointer;
}

.freeDelivery__declain {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #6c6c6c;
  cursor: pointer;
}

.promocode__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  z-index: 11;
  background-color: rgba(34, 37, 43, 0.2392156863);
}

.promocode__popup {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  max-width: 100%;
  width: 732px;
  height: 504px;
  max-height: 100%;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
}

.promocode__popup--image {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.promocode__popup--content {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  overflow: auto;
  justify-content: space-between;
  gap: 10px;
}

.promocode__popup--close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #8C8C8C;
    }
  }
}



.promocode-popup--text {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #22252B;
  display: block;
}
.promocode__popup--title {
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  color: #22252B !important;
  display: block;
}

.promocode__popup--paragraph {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #22252B;
  display: block;
  margin-bottom: 24px;
}

.promocode__popup--input {
  margin-bottom: 24px;
}

.promocode__popup--success {
  height: 100%;
  display: flex;
  flex-direction: column;

}

.promocode__success--content {
  flex: 1;
}

@media screen and (max-width: 580px) {
  .promocode__popup--image {
    display: none;
  }
  .promocode__popup {
    width: 320px;
    height: 464px;
  }
}

@media screen and (max-width: 345px) {
  .present__text {
    font-size: 11px;
  }

  .freeDelivery__title {
    font-size: 18px;
  }
}
