.exchange {
    padding-top: 116px;
}

.exchange__container {
    padding: 0px 100px ;
    max-width: 1440px;
}

.exchange__wrap {
    padding: 72px 0 ;
    display: flex;
    flex-direction: column;
    gap: 48px;
}




.exchange__title {
    font-family: "Gilroy", sans-serif;
    font-size: 48px;
    font-weight: 500;
    line-height: 1.125;
    color: #22252B;
    text-align: center;
    display: block;
}

.exchange__wrapper{
    display: flex;
    gap: 20px;
}

.exchange__subtitle {
    font-family: "Gilroy", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 37.66px;
    color: #22252B;

}

.exchange__text{
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #22252B;
    display: block;

    &:not(:last-child){
        margin-bottom: 8px;
    }

    &.bold {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 16px;
        display: block;
    }

    &.light {
        color: #8C8C8C;
    }
    &.center {
        text-align: center;
    }
}

.delivery__title {
    margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
    .exchange {
        padding-top: 60px;
    }
    .exchange__container {
        max-width: 768px;
        padding: 0px 16px 16px 16px;
    }

    .exchange__wrap {
        gap: 32px;
    }

    .exchange__text {
        font-size: 14px;
        &.bold {
            font-size: 20px;
        }
    }

    .exchange__title {
        font-size: 32px;
    }


}
