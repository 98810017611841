.sidebar__container--desktop .sliding-panel-container.active {
  top: 116px;
}
.sidebar__container--desktop .glass {
  backdrop-filter: blur(1px);
}
.sidebar__container--mobile {
  display: none;
}
.sidebar__container--desktop .panel-content {
  background: #fff;
  display: flex;
}

.sidebar__container--desktop .sidebar__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: calc(100vh - 114px);
}

.sidebar__container--desktop .sidebar__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
}

.sidebar__container--desktop .sidebar__link {
  display: flex;
  padding: 24px 24px 24px 100px;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #f5f5f5;
  background: #fff;

  width: 100%;
}

.sidebar__container--desktop .sidebar__link svg {
  fill: rgba(140, 140, 140, 1);
}

.sidebar__container--desktop .sidebar__link h3 {
  color: #22252b;
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.sidebar__container--desktop .sidebar__link:hover,
.sidebar__container--desktop .sidebar__link:focus {
  background: #f5f5f5;
}

.sidebar__container--desktop .sidebar__link:hover svg,
.sidebar__container--desktop .sidebar__link:focus svg {
  fill: rgba(252, 178, 95, 1);
}

.sidebar__container--desktop .sidebar__social--icons {
  display: flex;
  padding: 72px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.sidebar__container--desktop .sidebar__icon {
  display: flex;
  padding: 16px;
  align-items: center;

  border-radius: 100px;
  border: 2px solid #fdb360;
}

.sidebar__container--desktop .sidebar__icon svg {
  fill: rgba(34, 37, 43, 1);
}

@media screen and (max-width: 768px) {
  .sidebar__container--desktop {
    display: none;
  }

  .sidebar__container--mobile {
    display: flex;
  }
  .sidebar__container--mobile .glass {
    backdrop-filter: blur(1px);
  }

  .sidebar__container--mobile .panel-content {
    background: #fff;
    display: flex;
  }
  .sidebar__container--mobile .sidebar__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }

  .sidebar__container--mobile .sidebar__top {
    display: flex;
    justify-content: space-between;

    background: #22252b;
  }

  .sidebar__container--mobile .sidebar__logo--link {
    padding: 12px 16px;
  }

  .sidebar__container--mobile .sidebar__btn--close {
    background: inherit;
    border: none;
    width: 60px;
  }

  .sidebar__container--mobile .sidebar__caption {
    color: #8c8c8c;
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;

    padding: 24px 24px 8px 24px;
  }

  .sidebar__container--mobile .sidebar__menu {
    display: flex;
    padding-left: 24px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0 24px 24px;
  }

  .sidebar__container--mobile .sidebar__link {
    display: flex;
    height: 48px;
    padding: 8px 16px 8px 24px;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid #f5f5f5;
    background: #fff;

    width: 100%;
  }

  .sidebar__container--mobile .sidebar__link svg {
    width: 24px;
    height: 24px;
    fill: #8c8c8c;
  }

  .sidebar__container--mobile .sidebar__link h3 {
    color: #22252b;
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .sidebar__container--mobile .sidebar__nav {
    display: flex;
    width: 100%;
  }
  .sidebar__container--mobile .sidebar__nav--list {
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;

    background: #f5f5f5;

    width: 100%;
  }

  .sidebar__container--mobile .sidebar__nav--link {
    padding: 10px 16px 10px 0px;
  }

  .sidebar__container--mobile .sidebar__nav--item {
    color: #22252b;
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .sidebar__container--mobile .sidebar__nav--item--sales {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #ec1d25;
  }

  .sidebar__container--mobile .sidebar__bottom {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    margin-top: auto;

    background: #22252b;
  }

  .sidebar__container--mobile .sidebar__contact {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;

    border-radius: 4px;
    background: rgba(255, 255, 255, 0.08);
  }

  .sidebar__container--mobile .sidebar__contact--title {
    color: #fdb360;
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .sidebar__container--mobile .sidebar__contact--text {
    color: rgba(255, 255, 255, 0.64);
    font-family: "Gilroy", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .sidebar__container--mobile .sidebar__contact--link {
    color: #fdb360;
    text-decoration: underline;
  }

  .sidebar__container--mobile .sidebar__social--icons {
    display: flex;
    align-items: flex-start;
    gap: 12px;

    align-self: flex-start;
  }

  .sidebar__container--mobile .sidebar__icon {
    border-radius: 100px;
    border: 1px solid #fdb360;

    display: flex;
    padding: 8px;
    align-items: center;
  }
}
