.callback{
    padding: 75px 0;
    background-color: #292D34;
}

.callback__title{
    margin-bottom: 15px;
    font-family: "Georgia", sans-serif;
    font-size: 29px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 1.7549999952316284px;
    text-align: center;
    text-transform: uppercase;
    color:#FFFFFF;
}

.callback__description{
    max-width: 250px;
    width: 100%;
    margin: 0 auto 25px;
    font-family: "CenturyGothic";
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.callback__info{
    margin-bottom: 30px;
    font-family: "CenturyGothic", sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.38999998569488525px;
    text-align: center;
    color: #FFFFFF;
}

.callback__input{
    width: 100%;
    height: 58px;
    margin-bottom: 25px;
    padding-left: 30px;
    border: 1px solid #FCB25F;
    background-color: transparent;
    border-radius: 50px;
    font-family: "CenturyGothic", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;

    &::placeholder {
        font-family: "CenturyGothic", sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 54px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
    }

    &:focus{
        box-shadow: inset 0px -5px 15px 0px rgba(255, 255, 255, 0.1);
    }
}

.callback__button--wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 270px;
    width: 100%;
    height: 77px;
    margin: 0 auto 15px;
    border: 1px solid rgba(253, 231, 206, 0.5);
    border-radius: 80px;
    cursor: pointer;
}

.callback__button{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    width: 100%;
    height: 58px;
    padding: 8px;
    background: linear-gradient(355.74deg, #fcb25f 34.5%, #fde7ce 111.35%), linear-gradient(347.59deg, #fcb25f 21.12%, #fde7ce 97.18%);
    border-radius: 80px;
    border: transparent;
    box-shadow: 0px 0px 15px #fcb25f;
    font-family: "CenturyGothic", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: 0.8190000057220459px;
    text-align: center;
    cursor: pointer;
    color: #000000;
}

.callback__text{
    font-family: "CenturyGothic", sans-serif;
    font-size: 10.5px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.24400000274181366px;
    text-align: center;
    color: #FFFFFF;
}

.callback__phone{
    display: none;
}

@media screen and (min-width: 1000px) {
    .callback{
        position: relative;
        background-image: url('../assets/images/callback__bg.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 10% center;
    }

    .callback__phone{
        right: 5%;
        bottom: -35%;
        position: absolute;
    }

    .callback__title{
        font-size: 50px;
        text-align: left;
        margin-bottom: 20px;
    }

    .callback__description{
        margin: 0 auto 40px;
        max-width: none;
        text-align: left;
    }

    .callback__info{
        font-size: 25px;
        text-align: left;
    }

    .callback__input{
        max-width: 260px;
        height: 65px;
        margin: 0;
    }

    .callback__thumb{
        display: flex;
        align-items: center;    
        gap: 30px;
    }

    .callback__button--wrapper{
        max-width: 310px;
        height: 97px;
        margin: 0;
    }

    .callback__button{
        max-width: 279px;
        height: 66px;
    }

    .callback__text{
        text-align: left;
        font-size: 12px;
    }
}

@media screen and (max-width: 385px) {
    .callback__title{
        font-size: 26px;
    }
}

@media screen and (max-width: 350px) {
    .callback__title {
        font-size: 23px;
    }
}