.star {
  cursor: pointer;

  svg path {
    fill: #8c8c8c;
    transition: all 0.2s ease;
  }

  &.active {
    svg path {
      fill: #ff671f;
    }
  }
}

.rating__stars,
.rating__stars--input {
  width: 120px;
  display: flex;

  & > * {
    flex: 1;

    svg {
      width: 100%;
    }
  }
}

.reviews {
  padding: 72px 0 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.reviews__page {
  padding-top: 116px;
}

.reviews__container {
  padding: 0px 100px;
  max-width: 1440px;
}

.reviews__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviews__button {
  width: 173px;

  svg {
    display: none;
    transform: rotate(45deg);

    path {
      fill: #fff;
    }
  }
}

.reviews__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reviews__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgba(34, 37, 43, 0.2392156863);
  padding: 15px;
}

.reviews__popup--content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  width: 620px;
  background: #ffffff;
  border-radius: 10px;
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.reviews__popup--heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}

.reviews__popup--title {
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  color: #22252b;
}

.reviews__popup--close {
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: #8c8c8c;
    }
  }
}

.reviews__popup--body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.reviews__popup--inputs {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.reviews__popup--wrap {
  display: flex;
  width: 100%;
  gap: 24px;
}

.reviews__popup--button {
  width: 164px;
  margin: 0 auto;
  padding-bottom: 24px;
}

.reviews__input--wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.reviews__label {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #22252b;
  display: block;
  margin-bottom: 4px;
}

.reviews__input {
  font-family: "Gilroy", sans-serif;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  padding: 10px 16px;
  color: #22252b;
  font-size: 16px;
  &::placeholder {
    color: #8c8c8c;
  }
}

.reviews__success--wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 48px 16px;
  justify-content: center;
  flex: 1;
}

.reviews__success--image {
  width: 162px;
  margin: 0 auto;

  svg {
    width: 100%;
  }
}

.reviews__success-title {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #22252b;
  text-align: center;
  width: 100%;
  display: block;
}

.reviews__success--text {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #8c8c8c;
}

.review {
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.review__desktop {
  display: flex;
  gap: 48px;
}

.review__mobile {
  display: none;
}

.review__left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.review__date {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #22252b;
  text-wrap: nowrap;
  width: 140px;
  text-align: center;
}

.review__right {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.review__item--name {
  font-family: "Gilroy", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #22252b;
}

.review__item--text {
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #22252b;
}

.review__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__more {
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #22252b;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  padding: 0;
  margin-top: 12px;
}

@media screen and (max-width: 768px) {
  .reviews__page {
    padding-top: 60px;
  }

  .reviews__container {
    max-width: 768px;
    padding: 0px 16px 16px 16px;
  }

  .rating__stars {
    width: 80px;
  }
  .reviews {
    padding: 48px 0 0;
  }

  .reviews__button {
    width: 40px;
    height: 40px;

    span {
      display: none;
    }

    svg {
      display: block;
    }
  }

  .review__desktop {
    display: none;
  }

  .review__mobile {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .review__item--name {
    font-size: 16px;
  }

  .review__date {
    font-size: 12px;
    width: fit-content;
  }

  .review__item--text {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    line-clamp: 6;
    overflow: hidden;

    &.expanded {
      display: block;
      -webkit-line-clamp: unset;
      line-clamp: unset;
      overflow: visible;
    }
  }
}

@media screen and (max-width: 500px) {
  .reviews__popup {
    padding: 0;
  }

  .reviews__popup--content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .reviews__popup--heading {
    padding: 32px 24px 16px;
  }

  .reviews__popup--title {
    font-size: 24px;
  }

  .reviews__popup--close {
    width: 24px;
    height: 24px;
  }

  .reviews__popup--inputs {
    padding: 8px 24px;
  }

  .reviews__popup--wrap {
    flex-direction: column;
  }

  .reviews__popup--inputs {
    flex: 1;
  }

  .reviews__popup--button {
    width: 100%;
    padding: 24px;
    box-shadow: 0px 0px 34px 0px #00000014;
  }
}
