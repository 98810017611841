.footer {
    background: #22252B;
}

.footer__container {
    display: flex;
    padding: 0px 100px 16px 100px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 1440px;
}

.footer__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
}

.footer__logo--section {
    display: flex;
    width: 100%;
    max-width: 392px;
    padding: 48px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.footer__description {
    color: rgba(255, 255, 255, 0.64);
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
}

.footer__social--icons {
    display: flex;
    padding-top: 16px;
    align-items: flex-start;
    gap: 12px;
}

.footer__icon {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 10px;

    border-radius: 100px;
    border: 1px solid #FDB360;
}

.footer__nav {
    display: flex;
    width: 100%;
    max-width: 576px;
}

.footer__link--section {
    display: flex;
    padding: 48px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
}

.footer__title {
    color: #FFF;
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}

.footer__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.footer__list--item {
    color: #FFF;
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    text-decoration-line: underline;
}

.footer__contact {
    display: flex;
    padding: 48px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;   
    max-width: 272px; 

    background: rgba(255, 255, 255, 0.08);
}

.footer__contact--mobile {
    display: none;
}

.footer__contact--title {
    color: #FDB360;
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; 
}

.footer__contact--text {
    color: rgba(255, 255, 255, 0.64);
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
}

.footer__contact--link {
    color: #FDB360;
    font-family: Gilroy;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration-line: underline;
}

.footer__bottom {
    width: 100%;
    padding-top: 16px;
    border-top: 1px solid #FFF;
}

.footer__bottom--text {
    color: rgba(255, 255, 255, 0.32);
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    text-align: center;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .footer__description {
        font-size: 12px;
        line-height: 20px;
    }

    .footer__title {
        font-size: 14px;
        line-height: 20px;
    }

    .footer__list--item {
        font-size: 12px;
        line-height: 20px;
    }

    .footer__contact--title {
        font-size: 20px;
        line-height: 28px;
    }

    .footer__contact--text {
        font-size: 10px;
        line-height: 14px;
    }

    .footer__logo--icon {
        width: 197.8px;
        height: 54.6px;
    }
    
}

@media screen and (max-width: 768px) {
    .footer__container {
        max-width: 768px;
        padding: 0px 16px 16px 16px;
    }
    .footer__content {
        flex-direction: column;
        align-items: flex-start;
        
    }
    .footer__contact {
        display: none;
    }
    .footer__contact--mobile {
        display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;

    border-radius: 4px;
    background: rgba(255, 255, 255, 0.08);

    }
    .footer__nav {
        flex-direction: column;
        gap: 48px;
        padding-top: 24px;
        padding-bottom: 48px;
    }
    .footer__link--section {
        padding: 0px;
    }

    .footer__logo--section {
        padding-bottom: 24px;
    }
}