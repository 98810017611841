.wheelLand {
  padding-top: 70px;
  padding-bottom: 30px;
}

.wheelLand__title {
  font-family: "Avenir Next", sans-serif;
  font-size: 33.94px;
  font-weight: 700;
  line-height: 44.12px;
  text-align: center;
  color: #303030;
  text-transform: uppercase;
}

.wheelLand__text {
  font-family: "Avenir Next", sans-serif;
  font-size: 13.94px;
  font-weight: 600;
  line-height: 18.13px;
  text-align: center;
  color: #303030;
  text-transform: uppercase;
}

.wheelLand__image {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}

.wheelLand__button--wheel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 360px;
  height: 59px;
  margin: 0 auto;
  gap: 8px;
  border-radius: 8px;
  background: #362c12;
  border: 2px solid rgba(253, 231, 206, 1);
}

.wheelLand__button--text {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 17.67px;
}

.wheelLand__button--catalog {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 360px;
  height: 59px;
  margin: 11px auto 0;
  gap: 11px;
  border-radius: 8px;
  background: #303030;

  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 17.67px;
  color: #ffffff;
}

.wheelLand__info {
  max-width: 360px;
  width: 100%;
  margin: 12px auto 25px;
  font-family: "Avenir Next", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 19.84px;
  text-align: center;
  color: #303030;
}

.wheelLand__socialMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 166px;
  height: 27px;
  gap: 8px;
  margin: 0 auto;
  background: transparent;
  border: transparent;
  font-family: "Avenir Next", sans-serif;
  font-size: 14.78px;
  font-weight: 500;
  line-height: 20.19px;
  color: #303030;
}

.wheelLand__wrapper {
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 1000px) {
  .wheelLand {
    padding-top: 80px;
  }
}

@media screen and (max-width: 345px) {
  .wheelLand__socialMedia {
    font-size: 12.5px;
  }
}
