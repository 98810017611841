@import-normalize;

@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Medium"), local("Avenir-Next-Medium"),
    url("./assets/fonts/AvenirNext/AvenirNextCyr-Medium/AvenirNextCyr-Medium.woff2")
      format("woff2"),
    url("./assets/fonts/AvenirNext/AvenirNextCyr-Medium/AvenirNextCyr-Medium.woff")
      format("woff"),
    url("./assets/fonts/AvenirNext/AvenirNextCyr-Medium/AvenirNextCyr-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Demi"), local("Avenir-Next-Demi"),
    url("./assets/fonts/AvenirNext/AvenirNextCyr-Demi/AvenirNextCyr-Demi.woff2")
      format("woff2"),
    url("./assets/fonts/AvenirNext/AvenirNextCyr-Demi/AvenirNextCyr-Demi.woff")
      format("woff"),
    url("./assets/fonts/AvenirNext/AvenirNextCyr-Demi/AvenirNextCyr-Demi.ttf")
      format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Next";
  src: local("Avenir Next Bold"), local("Avenir-Next-Bold"),
    url("./assets/fonts/AvenirNext/AvenirNextCyr-Bold/AvenirNextCyr-Bold.woff2")
      format("woff2"),
    url("./assets/fonts/AvenirNext/AvenirNextCyr-Bold/AvenirNextCyr-Bold.woff")
      format("woff"),
    url("./assets/fonts/AvenirNext/AvenirNextCyr-Bold/AvenirNextCyr-Bold.ttf")
      format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic Bold"), local("CenturyGothic-Bold"),
    url("./assets/fonts/CenturyGothic/CenturyGothic-Bold.woff2") format("woff2"),
    url("./assets/fonts/CenturyGothic/CenturyGothic-Bold.woff") format("woff"),
    url("./assets/fonts/CenturyGothic/CenturyGothic-Bold.ttf")
      format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic"), local("CenturyGothic"),
    url("./assets/fonts/CenturyGothic/CenturyGothic.woff2") format("woff2"),
    url("./assets/fonts/CenturyGothic/CenturyGothic.woff") format("woff"),
    url("./assets/fonts/CenturyGothic/CenturyGothic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Georgia";
  src: local("Georgia Bold"), local("Georgia-Bold"),
    url("./assets/fonts/Georgia/Georgia-Bold.woff2") format("woff2"),
    url("./assets/fonts/Georgia/Georgia-Bold.woff") format("woff"),
    url("./assets/fonts/Georgia/Georgia-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Georgia";
  src: local("Georgia"), local("Georgia"),
    url("./assets/fonts/Georgia/Georgia.woff2") format("woff2"),
    url("./assets/fonts/Georgia/Georgia.woff") format("woff"),
    url("./assets/fonts/Georgia/Georgia.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Georgia";
  src: local("Georgia Bold Italic"), local("Georgia-BoldItalic"),
    url("./assets/fonts/Georgia/Georgia-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Georgia/Georgia-BoldItalic.woff") format("woff"),
    url("./assets/fonts/Georgia/Georgia-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Georgia";
  src: local("Georgia Italic"), local("Georgia-Italic"),
    url("./assets/fonts/Georgia/Georgia-Italic.woff2") format("woff2"),
    url("./assets/fonts/Georgia/Georgia-Italic.woff") format("woff"),
    url("./assets/fonts/Georgia/Georgia-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-ExtraBold.eot');
  src: local('Gilroy ExtraBold'), local('Gilroy-ExtraBold'),
  url('./assets/fonts/Gilroy/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-ExtraBold.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Heavy.eot');
  src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
  url('./assets/fonts/Gilroy/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-Heavy.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-LightItalic.eot');
  src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
  url('./assets/fonts/Gilroy/Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-LightItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-ThinItalic.eot');
  src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
  url('./assets/fonts/Gilroy/Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-ThinItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-BlackItalic.eot');
  src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
  url('./assets/fonts/Gilroy/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-BlackItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-BoldItalic.eot');
  src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
  url('./assets/fonts/Gilroy/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-BoldItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-SemiBold.eot');
  src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'),
  url('./assets/fonts/Gilroy/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-SemiBold.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-UltraLightItalic.eot');
  src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
  url('./assets/fonts/Gilroy/Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-UltraLightItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-SemiBoldItalic.eot');
  src: local('Gilroy SemiBold Italic'), local('Gilroy-SemiBoldItalic'),
  url('./assets/fonts/Gilroy/Gilroy-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-SemiBoldItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Light.eot');
  src: local('Gilroy Light'), local('Gilroy-Light'),
  url('./assets/fonts/Gilroy/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-Light.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-MediumItalic.eot');
  src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
  url('./assets/fonts/Gilroy/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-MediumItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-ExtraBoldItalic.eot');
  src: local('Gilroy ExtraBold Italic'), local('Gilroy-ExtraBoldItalic'),
  url('./assets/fonts/Gilroy/Gilroy-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-ExtraBoldItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Regular.eot');
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
  url('./assets/fonts/Gilroy/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-Regular.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-HeavyItalic.eot');
  src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
  url('./assets/fonts/Gilroy/Gilroy-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-HeavyItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Medium.eot');
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
  url('./assets/fonts/Gilroy/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-Medium.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-RegularItalic.eot');
  src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
  url('./assets/fonts/Gilroy/Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-RegularItalic.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-UltraLight.eot');
  src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
  url('./assets/fonts/Gilroy/Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-UltraLight.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Bold.eot');
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
  url('./assets/fonts/Gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-Bold.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Thin.eot');
  src: local('Gilroy Thin'), local('Gilroy-Thin'),
  url('./assets/fonts/Gilroy/Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-Thin.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy/Gilroy-Black.eot');
  src: local('Gilroy Black'), local('Gilroy-Black'),
  url('./assets/fonts/Gilroy/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/Gilroy/Gilroy-Black.woff') format('woff'),
  url('./assets/fonts/Gilroy/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}



input::placeholder {
  color: #292d34;
}

.error__validation {
  text-align: center;
  margin-bottom: 10px;
  color: rgb(220, 13, 13);
  font-family: "CenturyGothic", sans-serif;
}

.visually__hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  margin: -1px;
}
