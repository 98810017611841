.container.intro__container {
  max-width: 1440px;
  height: 532px;
  padding: 64px 100px;

  display: flex;
  gap: 20px;
}
.intro__container--second {
  display: flex;
  gap: 20px;
  width: 100%;
}
.intro__content--first {
  max-width: 472px;
  width: 100%;
}

.intro__content--second {
  max-width: 312px;
  width: 100%;
}
.intro__content--third {
  max-width: 416px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.intro__link {
  width: 100%;
  height: 100%;
  padding: 24px;

  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 10px;

  border-radius: 10px;
  background: #f5f5f5;
}

a.intro__link.intro__link--first {
  background-image: url("../assets/images/intro_section/wigs.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

a.intro__link.intro__link--second {
  background-image: url("../assets/images/intro_section/tails.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

a.intro__link.intro__link--third {
  background-image: url("../assets/images/intro_section/toppers.webp");
  background-size: contain;
  background-position: start;
  background-repeat: no-repeat;
}

.intro__link:hover,
.intro__link:focus {
  background: rgba(252, 178, 95, 0.2);
}

.intro__link:hover .intro__title,
.intro__link:focus .intro__title {
  color: rgba(252, 178, 95, 1);
}
.intro__link--container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  width: 100%;
}

.intro__link--content {
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 100%;
}

.intro__title {
  color: #22252b;
  font-family: "Gilroy", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.intro__text {
  color: #5f6063;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (max-width: 768px) {
  .container.intro__container {
    padding: 24px 12px;
    max-width: 768px;
    height: 762px;

    flex-direction: column;
    gap: 12px;
  }
  .intro__content--first {
    max-width: 100%;
  }
  .intro__link {
    padding: 16px;
  }
  .intro__link--first {
    height: 351px;
  }
  .intro__link-fourth .intro__link--container,
  .intro__content--third .intro__link--container,
  .intro__link--second .intro__link--container {
    flex-direction: column-reverse;
    align-items: start;
    gap: 8px;
  }
  .intro__container--second {
    height: 351px;
    gap: 12px;
  }

  .intro__title {
    font-size: 24px;
    line-height: 32px;
  }

  .intro__text {
    font-size: 14px;
    line-height: 20px;
  }

  .intro__arrow--mobile {
    fill: rgba(34, 37, 43, 1);
  }
}
