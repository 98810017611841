.header__panel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 60px;
  background-color: #22252b;
  border-bottom: 2px solid #fcb25f;
}

.header__tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 40px;
  border: 2px solid #fcb25f;
  border-radius: 80px;

  background-color: #22252b;
  color: #fcb25f;
  cursor: pointer;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  font-size: 19px;
}

.header__tab.active {
  color: #22252b;
  background-color: #fcb25f;
}

.model__panel--button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 50px;
  margin-top: 25px;
  color: #fcb25f;
  border-radius: 80px;
  border: 2px solid #fcb25f;
  background-color: #22252b;
  cursor: pointer;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  font-size: 19px;
}

.model__panel--input {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 50px;
  padding-left: 20px;
  border: 2px solid #fcb25f;
  background-color: #22252b;
  color: #fcb25f;
  border-radius: 80px;
  cursor: pointer;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  font-size: 19px;
}

.model__panel--input::placeholder {
  color: #fcb25f;
}

.panel__input--wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.panel__form {
  margin-top: 25px;
}

.model__panel--file {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 320px;
  height: 50px;
  padding-left: 20px;
  border: 2px solid #fcb25f;
  background-color: #22252b;
  color: #fcb25f91;
  border-radius: 80px;
  cursor: pointer;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  font-size: 19px;
}

.panel__item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding-left: 10px;
  border-radius: 8px;
  gap: 20px;
  background-color: #fcb25f;
  cursor: pointer;
}

.panel__image {
  width: 60px;
  height: 60px;
}

.panel__id {
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  font-size: 24px;
}

.panel__id--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 8px;
  border: 2px solid #22252b;
}

.panel__name {
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  font-size: 24px;
}

.panel__item--wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.panel__button--hide {
  width: 100px;
  height: 80px;
  border: transparent;
  border-radius: 8px;
  border: 2px solid #fcb25f;
  background-color: #22252b;
  color: #fcb25f;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  font-size: 20px;
  cursor: pointer;
}

.panel__up--button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 42px;
  background-color: transparent;
  transform: rotate(90deg);
  cursor: pointer;
  border: 2px solid #fcb25f;
  border-radius: 8px;
}

.panel__down--button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 42px;
  background-color: transparent;
  transform: rotate(270deg);
  cursor: pointer;
  border: 2px solid #fcb25f;
  border-radius: 8px;
}

.panel__image--preview {
  position: absolute;
  right: -70px;
  width: 50px;
  height: 50px;
}

.filter__panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  width: 600px;
  padding-top: 50px;
  margin: 0 auto;
}

.filter__panel--input {
  height: 40px;
  width: 100%;
  padding-left: 15px;
  background-color: #ffffff;
  border: transparent;
  border-radius: 12px;
}

.filter__panel--wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.filter__panel--text {
  padding-left: 15px;
  background-color: #22252b;
  color: #fcb25f;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
  font-size: 19px;
}
