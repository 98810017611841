.thanks__hero {
  padding-top: 140px;
  padding-bottom: 25px;
  background-image: url("../assets/images/bg__thanks.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.thanks__header--title {
  font-family: "CenturyGothic", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  width: 40%;
}

.thanks__description {
  margin-bottom: 120px;
  font-size: 17px;
  text-align: center;
  font-family: "CenturyGothic", sans-serif;
  line-height: 155.4%;
  letter-spacing: 0.025em;
  color: #ffffff;
}

.thanks__header--wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.thanks__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thanks__button--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 296px;
  width: 100%;
  height: 77px;
  margin-bottom: 20px;
  border: 1px solid rgba(253, 231, 206, 0.5);
  border-radius: 80px;
  cursor: pointer;
}

.thanks__button {
  max-width: 277px;
  width: 100%;
  height: 59px;
  border: transparent;
  background: linear-gradient(355.74deg, #fcb25f 34.5%, #fde7ce 111.35%),
    linear-gradient(347.59deg, #fcb25f 21.12%, #fde7ce 97.18%);
  border-radius: 80px;
  -webkit-box-shadow: 0px 0px 15px #fcb25f;
  box-shadow: 0px 0px 15px #fcb25f;
  color: #000000;
  font-size: 13.7px;
  letter-spacing: 0.06em;
  font-weight: 700;
  font-family: "CenturyGothic", sans-serif;
}

.thanks__title {
  margin-bottom: 20px;
  font-size: 29px;
  font-family: "Georgia", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  line-height: 101.8%;
  text-align: center;
  font-weight: 700;
}

.thanks__title--accent {
  background: #64daff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.thanks__title--new {
  margin: 15px 0;
  font-family: "Avenir Next", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #292d34;
}

.thanks__line {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 358px;
  width: 100%;
  height: 1px;

  background-color: #d1d1d1;
}

.thanks__cart--list {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 10px;
  max-width: 358px;
  width: 100%;
  margin: 10px auto;
}

@media screen and (min-width: 1000px) {
  .thanks__description {
    max-width: 350px;
    width: 100%;
    margin-bottom: 30px;
    text-align: left;
    font-size: 16px;
  }

  .thanks__button--wrapper {
    max-width: 316px;
    height: 97px;
    margin: 0;
  }

  .thanks__button {
    max-width: 285px;
    height: 66px;
  }

  .thanks__wrapper {
    flex-direction: row;
    justify-content: flex-start;
    gap: 50px;
  }

  .thanks__hero {
    padding-top: 200px;
    padding-bottom: 120px;
  }

  .thanks__title {
    max-width: 860px;
    width: 100%;
    font-size: 70px;
    text-align: left;
  }

  .thanks__header--title {
    margin-left: 90px;
  }

  .thanks__header--wrapper {
    justify-content: flex-start;
  }
}

.thanks__gradient {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  background: linear-gradient(294.24deg, #fcb25f 47%, #fde7ce 88.91%);
}

.thanks__additional {
  background-image: url("../assets/images/catalog__bg2.webp");
  background-size: cover;
}

.thanks__additional--title {
  font-size: 17px;
  font-weight: 700;
  line-height: 22.1px;
  font-family: "CenturyGothic", sans-serif;
  color: #303030;
}

.thanks__totalInfo--wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 358px;
  width: 100%;
  margin: 10px auto;
}

.thanks__totalInfo {
  display: flex;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.67px;
  color: #33373e;
}

.thanks__goBack--button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: transparent;
  max-width: 358px;
  width: 100%;
  height: 58px;
  margin: 10px auto;
  border-radius: 8px;
  background: #292d34;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 17.67px;
  color: #ffffff;
}

@media screen and (max-width: 365px) {
  .thanks__header--title {
    font-size: 18px;
    line-height: 18px;
  }
}

@media screen and (max-width: 360px) {
  .thanks__title {
    font-size: 24px;
  }
}

@media screen and (max-width: 335px) {
  .thanks__header--title {
    font-size: 16px;
    line-height: 16px;
  }
}
