.payAfterTry {
  padding: 25px 0;
}

.payAfterTry__title {
  font-family: "Avenir Next", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: center;
  color: #303030;
}

.payAfterTry__image {
  max-width: 258px;
  width: 100%;
  margin: 16px auto;
}

.payAfterTry__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
}

.payAfterTry__text {
  font-family: "Avenir Next", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  color: #303030;
}

.payAfterTry__line {
  height: 1px;
  width: 100%;
  background: #ff9520;
  margin: 12px 0;
}

.payAfterTry__description {
  font-family: "Avenir Next", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  color: #303030;
}
