html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

image {
  display: block;
  max-width: 100%;
  height: auto;
}

body {
  margin: 0;
  font-family: "Georgia", "CenturyGothic", "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.block__scroll {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 425px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@media screen and (min-width: 1000px) {
  .container {
    max-width: 1440px;
    padding: 0 100px;
  }
}

.fade {
  background: linear-gradient(294.24deg, #fcb25f 47%, #fde7ce 88.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.body__overflow {
  height: 100vh;
  overflow: hidden;
}

@import "./header.scss";
@import "./sideBar.scss";
@import "./hero.scss";
@import "./intro.scss";
@import "./catalog.scss";
@import "./about.scss";
@import "./product.scss";
@import "./callback.scss";
@import "./reason.scss";
@import "./benefits.scss";
@import "./sales.scss";
@import "./reviews.scss";
@import "./delivery.scss";
@import "./advantages.scss";
@import "./subscription.scss";
@import "./footer.scss";
@import "./model.scss";
@import "./thanks.scss";
@import "./modal.scss";
@import "./exchange.scss";
@import "./preloader.scss";
@import "./popup.scss";
@import "./sms.scss";
@import "./new.scss";
@import "./blockComponent.scss";
@import "./reviewCard.scss";
@import "./adminPanel.scss";
@import "./gift.scss";
@import "./wheel.scss";
@import "./smsForm.scss";
@import "./smsSent.scss";
@import "./wheelLand.scss";
@import "./cart.scss";
@import "./novaPoshta.scss";
@import "./forWho.scss";
@import "./isSizeSuit.scss";
@import "./payAfterTry.scss";
@import "./search.scss";
@import "./checkout.scss";
@import "./lastSeen.scss";
@import "./oneClick.scss";
